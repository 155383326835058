import React, {useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnSysError = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
 });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <Header screen="" image="bePart"/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450" colSpan="2" valign="top">
              <p>&nbsp;</p>
                <p >We're sorry!!!  The system is currently unavailable.
        Please try again later...</p><p>&nbsp;</p>
        </td>
        </tr>

                <EnLegalFooter/>
        </Table>
        </div>
    );
};


export default EnSysError;
