import React,{useEffect} from 'react';
import {Table} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';

const SpThreeTrys = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
<tbody>
        <SpHeader image="bePart" screen=""/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
<p>&nbsp;</p>
                <p >Disculpe, el n&uacute;mero de serie que ingres&oacute; no
        corresponde con nuestros registros, s&iacute;rvase llamar al 1-800-204-3174.</p>

        <p>&nbsp;</p>
        </td>
    </tr>

        <SpLegalFooter/>
        </tbody>
</Table>
</div>
    );
};


export default SpThreeTrys;
