import React from 'react';
import { Table} from 'react-bootstrap';
import SpCopyRights from "../components/SpCopyRights";

class SpWork extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }

  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }


  componentDidMount() {
    document.title = "Afiliación a los Medios";
  }

  render(){
  return (
    <div align="center">
<Table cellSpacing="0" cellPadding="0" border="0">
<tbody>
  <tr>
    <td height="40" valign="top" align="left" width="20" rowSpan="2">
      <td align="left">&nbsp;</td>
    </td>
    <td height="12" valign="middle" align="left">&nbsp; </td>
    <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
  </tr>
  <tr>
    <td height="20" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></td>
  </tr>
  <tr>
    <td valign="top" height="210"></td>
    <td width="410" height="210" valign="top" align="left">
      <p>Nos gustar&iacute;a saber si alg&uacute;n miembro de su hogar es voluntario, alumno interno o empleado
       de tiempo completo o parcial de una:</p>
       <ul>
        <li>Estaci&oacute;n o cadena de televisi&oacute;n o radio</li>
        <li>Compa&ntilde;&iacute;a de televisi&oacute;n por cable</li>
        <li>Empresa u organizaci&oacute;n controlante de una entidad emisora o controlada por ella, o</li>
        <li>Un servicio de medici&oacute;n de medios</li>
      </ul><p><br></br></p>
        <p style={{marginBottom:'0px'}}><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></p>
        </td>
        <td height="210" valign="top" align="left">&nbsp;</td>
        </tr>
        <tr>
        <td valign="top" height="20"></td>
        <td width="408" valign="top"><br/>
        <SpCopyRights/>
        </td>
        <td valign="top">&nbsp;</td>
        </tr>
        </tbody>
        </Table>
        </div>
  );
}
}

export default SpWork;
