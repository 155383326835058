import React,{useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnTeenLockOut = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
 });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <Header image="thankyou" screen=""/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
              <p>&nbsp;</p>
                <p >Unfortunately, you need to be age 16 or older to enroll in our survey.
         Please have a member of your household 16 or older enroll in the survey.
         Thank you. If you have any questions call us toll free at 1-800-204-3174.</p>
         <p>&nbsp;</p>
         </td>
         </tr>

                 <EnLegalFooter/>
         </Table>
         </div>
    );
};


export default EnTeenLockOut;
