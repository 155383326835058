import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Table} from 'react-bootstrap';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';


const SpInProgress =(props)=> {

  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });


  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <SpHeader screen="" image="bePart"/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
            <p>&nbsp;</p>
                <p>***   '{props.sample_id}'  is not available for use!   ***</p>
                      <p>&nbsp;</p>
                      </td>
                  </tr>

                      <SpLegalFooter/>
              </Table>
            </div>
    );
  };



export default SpInProgress;
