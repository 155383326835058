import React from 'react';

import {  Table} from 'react-bootstrap';
import SpCopyRights from '../components/SpCopyRights';
import { Helmet } from 'react-helmet';

class SpHowSelected extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }

  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }

  render(){
    return (
      <div align="center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>&#191;C&#243;mo seleccionaron mi hogar?</title>
      </Helmet>
  <Table cellSpacing="0" cellPadding="0" border="0">
  <tbody>
    <tr>
      <td height="40" valign="top" align="left" width="20" rowSpan="2">
        <td align="left">&nbsp;</td>
      </td>
      <td height="12" valign="middle" align="left">&nbsp; </td>
      <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
    </tr>
    <tr>
      <td height="30" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></td>
    </tr>
    <tr>
      <td valign="top" height="210"></td>
      <td width="410" height="210" valign="top" align="left">
        <p align="left"><span className="headline">&#191;C&#243;mo seleccionaron mi hogar?</span></p>
        <p align="left">Los Ratings de Nielsen recopilan lo que escuchan las personas de 12 a&#241;os de edad y mayores en los Estados Unidos. Debido a que no podemos hablar con todas las personas, escogemos una muestra al azar para representar a toda la poblaci&#243;n. Una computadora selecciona hogares escogiendo n&#250;meros de tel&#233;fono al azar, muy parecido a la forma como los n&#250;meros de la loter&#237;a son seleccionados. Tenemos las mismas posibilidades de llamar a n&#250;meros publicados como no publicados.  Su participaci&#243;n asegura que los ratings de radio representen a las personas de su comunidad.</p>
        <br/>
        <p><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></p>
      </td>
      <td height="210" valign="top" align="left">&nbsp;</td>
    </tr>
    <tr>
      <td valign="top" height="20"></td>
      <td width="408" valign="top">
      <SpCopyRights/>
      </td>
      <td valign="top">&nbsp;</td>
    </tr>
    </tbody>
  </Table>
</div>
 );
}
}

export default SpHowSelected;
