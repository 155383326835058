import React, {useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Table } from 'react-bootstrap';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';

const SpMisType = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings'
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });

  const refresh=()=>{
    this.setState({nextPage:''})
  }

  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
        <SpHeader image="bePart" screen=""/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450" height="205" colSpan="2" valign="top">
<p>&nbsp;</p>
    <p><span className="headline">&iquest;Cometi&oacute; un error al entrar el n&uacute;mero de serie?</span></p>
    <p>Su n&uacute;mero de serie no coincide con el de ning&uacute;n posible participante
                      de la encuesta. Quiz&aacute;s lo entro mal. De ser as&iacute;, seleccione el bot&oacute;n
                      &quot;back&quot; (atr&aacute;s), e intente de nuevo. Para ser elegible para esta encuesta,
                      su hogar debe haber recibido una tarjeta postal o una carta con su n&uacute;mero de serie
                      &uacute;nico. Le agradecemos su inter&eacute;s y esperamos que si elegimos su hogar en el
                      futuro, siga deseando participar.</p>
    <p>&nbsp;</p>
    <p align="center"><Link to= "/SpHome" onClick={refresh} replace >Haga click aqu&iacute; para intentar nuevamente..</Link></p>
    </td>
</tr>

        <SpLegalFooter/>
        </tbody>
</Table>
</div>
    );
};


export default SpMisType;
