import React from 'react';
import {Table} from 'react-bootstrap';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';
import { Helmet } from 'react-helmet';
import { WithRouter } from '../components/WithRouter';

class SpThankYou extends React.Component  {

  state={
    survey_date:''
  }

  constructor(props){
    super(props);
    this.state.survey_date = props.location.state;
  }
  componentDidMount() {
      window.addEventListener("popstate", () => {
       this.props.navigate(1);
     });
 }

   toggleWhatsComing= () => {
    window.open('/SpWhatsComing','','scrollbars=no,resizable=no,height=450,width=450');
  }

render(){
  return (
    <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>&iexcl;Gracias!</title>
    </Helmet>
    <Table cellSpacing="0" cellPadding="0" border="0">
      <tbody>
        <SpHeader image="thankyou" screen=""/>

  <tr>
    <td width="20"  valign="top"></td>
    <td width="413" valign="top" align="left">
        <p>&nbsp;</p>
        <p>&iexcl;Gracias por inscribirse!</p>
                <p > <a href="#" onClick={this.toggleWhatsComing}>Espere recibir su paquete de diarios por correo.</a>. </p>
        <p>Recibir&aacute; un paquete de cuadernitos en su hogar unos días antes del comienzo de la encuesta.
         El paquete incluir&aacute; un peque&ntilde;o regalo en efectivo, suficiente para comprarse un cafecito o una dona,
         como muestra de agradecimiento por ayudarnos con la encuesta. El primer d&iacute;a de la encuesta es el jueves {this.state.survey_date}.</p>

         <p>Como cada hogar es importante, le llamaremos antes de que comience la encuesta para asegurarnos que
         haya recibido los cuadernitos y para responderle cualquier duda que pueda tener.</p>
       <p>Al finalizar la semana de la encuesta, env&iacute;e los cuadernitos de vuelta a Nielsen.
         Cada cuadernito incluye el franqueo postal y la direcci&oacute;n a donde debe enviarlo.</p>
      <p>Si tiene alguna pregunta, comun&iacute;quese con nosotros llamando al 1&#8209;800&#8209;204&#8209;3174.</p>

        </td>
        </tr>

                <SpLegalFooter/>
                </tbody>
        </Table>

    </div>
    );
  }
}



export default WithRouter(SpThankYou);
