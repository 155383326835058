import React from 'react';

import {  Table} from 'react-bootstrap';
import SpCopyRights from '../components/SpCopyRights';
import { Helmet } from 'react-helmet';

class SpOrigin extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }

  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }

  render(){
    return (
      <div align="center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>&iquest;Qu&eacute; significa &quot;origen&quot;?</title>
      </Helmet>
  <Table cellSpacing="0" cellPadding="0" border="0">
  <tbody>
    <tr>
      <td height="40" valign="top" align="left" width="20" rowSpan="2">
        <td align="left">&nbsp;</td>
      </td>
      <td height="12" valign="middle" align="left">&nbsp; </td>
      <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
    </tr>
    <tr>
      <td height="30" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></td>
    </tr>
    <tr>
      <td valign="top" height="210"></td>
      <td width="410" height="210" valign="top" align="left">
        <p ><span className="headline">&iquest;Qu&eacute;
          significa "español, hispano, o latino"?</span></p>
        <p>Para asegurarnos de que los ratings sean confiables, debemos incluir todo tipo de personas.
         Si alg&uacute;n miembro de su hogar es de origen español, hispano, o latino marque la casilla correspondiente.
         Algunos ejemplos de personas de origen espanolas, hispanas, o latinas son: mexicanos, mexicano-estadounidenses, chicanos,
         puertorrique&ntilde;os, u otras personas hispanas o latinas.</p>
        <br/>
        <p><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></p>
      </td>
      <td height="210" valign="top" align="left">&nbsp;</td>
    </tr>
    <tr>
      <td valign="top" height="20"></td>
      <td width="408" valign="top">
      <SpCopyRights/>
      </td>
      <td valign="top">&nbsp;</td>
    </tr>
    </tbody>
  </Table>
</div>
 );
}
}

export default SpOrigin;
