import React from 'react';
import { Table} from 'react-bootstrap';

const EnLegalFooter = () => {
    return (
      <tr>
      <td valign="top"></td>
      <td colSpan="2">
      <Table>
    <tbody>
    <tr>
  <td width="444">
    <p align="left">
    </p>
    <p style={{fontSize:'x-small',marginBottom:'0px'}} align="center"><font size="-2">
      <a href="http://www.nielsenradiodiary.com/privacy.htm" rel="noreferrer noopener" target="_blank">Privacy Statement</a>
    {' '}  |{' '}<a href="http://www.nielsenradiodiary.com/terms.htm" rel="noreferrer noopener" target="_blank">Terms of Use</a></font>
    </p>
  </td>
</tr>
<tr>
  <td width="444" height="206" valign="top">
    <p style={{marginTop:"0px"}}className="verysmall" align="center">Copyright &copy; {(new Date().getFullYear())} The Nielsen Company (US), LLC. All Rights Reserved.</p>
  </td>
</tr>
</tbody>
</Table>
</td>
</tr>



    );
};

export default EnLegalFooter;
