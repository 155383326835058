import React,{useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';

const SpPhoneLockOut = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <SpHeader image="thankyou" screen="" />

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450" colSpan="2" valign="top">
    <p>&nbsp;</p>
                <p >El segundo tel&eacute;fono que nos brind&oacute; no coincide con el que tenemos registrado.
         Quiz&aacute;s reciba una llamada de un asistente de investigaci&oacute;n de Nielsen en los
         pr&oacute;ximos días para verificar su participaci&oacute;n en la encuesta. Si tiene
         alguna pregunta, comun&iacute;quese con nosotros llamando gratis al 1-800-204-3174. Gracias.</p>
         <p>&nbsp;</p>
         </td>
     </tr>

         <SpLegalFooter/>
 </Table>
 </div>
    );
};


export default SpPhoneLockOut;
