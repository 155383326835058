import React,{useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';

const SpNotCellOnly = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <SpHeader image="thankyou" screen=""/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
  <p>&nbsp;</p>
                <p>Lamentablemente, para este proyecto estamos buscando hogares que no tengan una l&#237;nea de tel&#233;fono fija. Gracias por su inter&#233;s.</p>
                <p>&nbsp;</p>
                </td>
            </tr>

                <SpLegalFooter/>
        </Table>
        </div>
    );
};


export default SpNotCellOnly;
