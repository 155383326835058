import React from 'react';
import { Table} from 'react-bootstrap';
import SpCopyRights from "../components/SpCopyRights";
import Mailpieces_sp_short from "../images/mailpieces_sp_short.jpg";
import { Helmet } from 'react-helmet';

class SpWhatsComing extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }

  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }

  render(){
  return (

    <div align="center">
    <Helmet>
      <meta charSet="utf-8" />
      <title>&#191;Qu&#233; vendr&aacute; en el correo?</title>
    </Helmet>
<Table cellSpacing="0" cellPadding="0" border="0">
<tbody>
  <tr>
    <td height="40" valign="top" align="left" width="20" rowSpan="2">
      <td align="left">&nbsp;</td>
    </td>
    <td height="12" valign="middle" align="left">&nbsp; </td>
    <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
  </tr>
  <tr>
    <td height="20" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></td>
  </tr>
  <tr>
    <td valign="top" height="210"></td>
    <td width="410" height="210" valign="top" align="left">
      <p><span className="headline">&#191;Qu&#233; vendr&aacute; en el correo?</span></p>
        <p align="left">Si ya acept&#243; participar, pronto recibir&#225; una caja de colores como la que aparece en la foto de abajo. Esta caja contiene los materiales que necesita para dejarle saber a las estaciones de radio lo que usted escucha.</p>
        <p><img src= {Mailpieces_sp_short} alt="" height="250" width="410"/></p>
        <p style={{marginBottom:'0px'}}><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Presione aquí para cerrar la ventana.</a></p>
        </td>
        <td height="210" valign="top" align="left">&nbsp;</td>
        </tr>
        <tr>
        <td valign="top" height="20"></td>
        <td width="408" valign="top"><br/>
        <SpCopyRights/>
        </td>
        <td valign="top">&nbsp;</td>
        </tr>
        </tbody>
        </Table>
        </div>
  );
}
}

export default SpWhatsComing;
