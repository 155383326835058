import React,{useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';

const SpAffiliated = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <SpHeader screen="" image="thankyou"/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
  <p>&nbsp;</p>
                <p >Lamentablemente, como respondi&oacute; que un miembro de su hogar trabaja para una estaci&oacute;n
         o red de radio o televisi&oacute;n, o para un sistema o red de cable, no podemos incluir
         su hogar en nuestra encuesta. Gracias por su inter&eacute;s.</p>
         <p>&nbsp;</p>
         </td>
     </tr>

         <SpLegalFooter/>
 </Table>
 </div>
    );
};


export default SpAffiliated;
