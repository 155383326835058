import React from 'react';
import {Form, Table} from 'react-bootstrap';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';
import Arrow2 from "../images/arrow2.jpg";
import { WithRouter } from "../components/WithRouter";
import Auth from "../model/Auth";
import { API } from 'aws-amplify';
import { SpSysError, SessionExpired, SpAffiliated, SpAlreadyPart,SpNinePlus, SpPhoneLockOut } from './';



class SpPhConfirm  extends React.Component  {

  state = {
      hh_param:{
        sample_id: '',
        languageUsed:'Sp',
        nextPage: '',
        pph_confirm:'',
        original_token:'',
        frameCode:''
      },
     field_req:'none'
   }

  constructor(props){
    super(props);
    this.state.hh_param = JSON.parse(window.sessionStorage.getItem('hh_param'));
    this.state.pph_confirm = '';
    if(props.location.state.pph_confirm){
      this.state = props.location.state;
    }else{
      this.state.back_data = props.location.state;
    }
    if(this.state.hh_param.householdNum > 1){
      this.state.people = 'personas de 12 años de edad o mayores';
    }else{
      this.state.people = 'persona de 12 años de edad o mayor';
    }
    this.onRespondentHandler = this.onRespondentHandler.bind(this);
  }

  componentDidMount() {
    document.title = "Nielsen Radio Ratings";
    window.addEventListener("popstate", () => {
     this.props.navigate(1);
   });
 }


  formChange = (e) =>{
  this.setState({ pph_confirm:e.target.value});
  }

  validate(){
    if(!this.state.pph_confirm){
      this.setState({
        field_req: 'inline',
      });
      return false;
    }else{
      this.setState({
        field_req: 'none',
      });
      window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));
      return true;
    }
  }

  back=()=> {
   this.props.navigate("/SpPersonalInfo",{replace:true , state:this.state.back_data});
 }
 setStateSynchronous(stateUpdate) {
      return new Promise(resolve => {
          this.setState(stateUpdate, () => resolve());
      });
  }

  async  onRespondentHandler(e) {
    e.preventDefault();
    if(this.validate()) {

     if(this.state.pph_confirm === 'N'){
       // go back to pinfo with error
       const back_data = { ...this.state.back_data,pPHConfirm: 'inline' };
       await this.setStateSynchronous(state => ({back_data:back_data}));
       Auth.login(() => {
         this.props.navigate("/SpPersonalInfo",{replace:true , state:this.state.back_data});
       });
     }else{
       const apiName = 'econsent';
       const path = 'econsent/response';
       const myInit = {
         headers: {
          'headerauth1':   window.sessionStorage.getItem('token')
        },
         body: {
           'hh_param': JSON.parse(window.sessionStorage.getItem('hh_param')),
           'action':'phConfirm'
         },
       };
       API.post(apiName, path, myInit)
         .then(res => {
             this.setState({hh_param:res.form_data})
             // first remove the storage before setting the response
             window.sessionStorage.removeItem('hh_param');
             window.sessionStorage.setItem('token',res.token)
             window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))

             Auth.login(() => {
               if (res.form_data.nextPage === 'AGE_GENDER'){
                 this.props.navigate("/SpAgeGender",{replace:true , state:this.state});
               }
            });
          })
        }
    }else{
      console.log('Invalid Form');
    }
  }

  render(){
    const {nextPage} = this.state.hh_param;
    if (nextPage ===  'SYS_ERROR'){
      return(<SpSysError/>)}
    if(nextPage === 'SESSION_EXPIRED'){
      return(<SessionExpired/>)}
    if (nextPage ===  'AFFILIATED'){
      return(<SpAffiliated/>)}
    if (nextPage ===  'HOUSEHOLD_NINE_PLUS'){
      return(<SpNinePlus/>)}
    if (nextPage ===  'PHONE_LOCKOUT'){
      return(<SpPhoneLockOut/>)}
    if (nextPage ===  'ALREADYPART'){
      return(<SpAlreadyPart/>)}

  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
    <SpHeader screen="" image="bePart"/>
  <tr>
    <td width="25"  valign="top" >&nbsp;</td>
    <td colSpan="3" valign="top" width="450">
    <Table width="450" border="0">
    <tbody>
	  <tr>
	  	<td width="450"><b>* Usted nos ha indicado que en su hogar hay {this.state.hh_param.householdNum}{' '}{this.state.people}.  &#191;Es esto correcto?</b><br/>

        <input type="radio" id="pph_confirm" name="pph_confirm" value="Y" checked={this.state.pph_confirm === "Y"} onChange={this.formChange}/>
        S&#237;
        <input type="radio" id="pph_confirm" name="pph_confirm" value="N" checked={this.state.pph_confirm === "N"} onChange={this.formChange} />
        No


        	<img style={{display:this.state.field_req}}  src={Arrow2} width="36" height="18" border="0" alt="arrow"/>
        <font style={{display:this.state.field_req, color:"red"}} ><b>&nbsp;&nbsp;Se require una respuesta.</b></font>

        </td>
         </tr>
   <tr>
     <td>&nbsp;</td>
   </tr>
   </tbody>
       </Table>
   </td>
 </tr>
 <tr>
   <td width="25"  valign="top" height="50">&nbsp;</td>
   <td width="450" colSpan="3">
   </td>
 </tr>
 <tr>
   <td width="25"  valign="top">&nbsp;</td>
   <td width="450" colSpan="3"> <p align="left"></p>
   <Form onSubmit = {this.onRespondentHandler}>
       <p align="center"><button type="button" name="back" onClick={this.back}>Regresar</button>{' '}<input type="submit" name="continue_to_3" value="Continuar"/></p>
       </Form>
   </td>
 </tr>
 <tr>
   <td width="25"  valign="top">&nbsp;</td>
   <td width="450" colspan="3">
      <SpLegalFooter/>
    </td>
  </tr>
  </tbody>
</Table>
    </div>
    );
}
}


export default WithRouter(SpPhConfirm);
