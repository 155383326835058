import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnNinePlus = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <Header image="thankyou" screen=""/>

    <tr>
      <td width="19"  valign="top"></td>
      <td width="450" colSpan="2" valign="top">
          <p>&nbsp;</p>
                <p >Unfortunately, we cannot include households with more than
      nine people in our survey. Thank you for your interest.</p>
      <p>&nbsp;</p>
      </td>
      </tr>

              <EnLegalFooter/>
      </Table>
      </div>
    );
};


export default EnNinePlus;
