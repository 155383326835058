import React , {useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnAffiliated = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });

  return (
    <div> 
    <Table cellSpacing="0" cellPadding="0" border="0">

        <Header screen="" image = "thankyou"/>
  <tr>
    <td width="19"  valign="top"></td>
    <td width="450" colSpan="2" valign="top">
        <p>&nbsp;</p>

                <p>Unfortunately, since you responded that someone in your household works
        for a radio or television station or network, or a cable system or network,
        we are not able to include your household in our survey. Thank you for
      your interest.</p><p>&nbsp;</p>
      </td>
      </tr>

              <EnLegalFooter/>
      </Table>
      </div>
    );
};


export default EnAffiliated;
