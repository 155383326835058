import React, {useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnPhoneLockOut = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <Header screen="" image="thankyou"/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
              <p>&nbsp;</p>
                <p >The second telephone number you have provided did not match our records.
         You may receive a call from a Nielsen research assistant in the next few
         days to verify your participation in the survey.  If you have any
         questions, contact us toll free at 1-800-204-3174.  Thank you.</p>  <p>&nbsp;</p>
         </td>
         </tr>

                 <EnLegalFooter/>
         </Table>
         </div>
    );
};


export default EnPhoneLockOut;
