import React, {useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';

const SpSysError = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <SpHeader image="bePart" screen=""/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450" colSpan="2" valign="top">
<p>&nbsp;</p>
                <p>Lo sentimos, actualmente el sistema no esta disponible. Por favor trate de nuevo más tarde.</p>
                <p>&nbsp;</p>
                </td>
            </tr>

                <SpLegalFooter/>
        </Table>
        </div>
    );
};


export default SpSysError;
