import React from 'react';
import {Table, Form} from 'react-bootstrap';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';
import Arrow2 from "../images/arrow2.jpg";
import { WithRouter } from "../components/WithRouter";
import { API } from 'aws-amplify';
import Auth from "../model/Auth";

import {SpSysError, SpPhoneLockOut,SpAffiliated, SpNinePlus, SessionExpired, SpAlreadyPart} from './';

class SpPersonalInfo  extends React.Component  {

  state = {
    hh_param:{
      sample_id: '',
      languageUsed:'SP',
      nextPage: '',
      ofAge:'',
      iBadLoginCount:0,
      frameCode:'',
      original_token:''
    },
    pinfo:{
      title:'',
      title_text:'',
      firstName:'',
      lastName:'',
      addrStreet1:'',
      addrApt:'',
      addrCity:'',
      addrState:'',
      addrZip:'',
      phoneAreaCode:'',
      phonePrefix:'',
      phoneSufix:'',
      networkAffiliate:'',
      householdNum:''
    },
   errorAddress:'none',
   errorState:'none',
   errorCity:'none',
   errorZip:'none',
   errorInvalidZip:'none',
   errorInvalidPhone:'none',
   errorPhone:'none',
   errorPhoneNmbrMismatch:'none',
   errorAffiliation:'none',
   pPHConfirm:'none',
   errorHouseholdNmbr:'none',
   error:'none'
 }

constructor(props){
  super(props);
  this.state.hh_param = JSON.parse(window.sessionStorage.getItem('hh_param'));
  this.state.pinfo ={'title':'','title_text':'','firstName':'','lastName':'','addrStreet1':'','addrApt':'','addrCity':'','addrState':'','addrZip':'','phoneAreaCode':'','phonePrefix':'','phoneSufix':'','networkAffiliate':'','householdNum':''};
  if(props.location.state.pinfo){
    this.state = props.location.state;
    if (this.state.pPHConfirm === 'inline'){
      this.state.errorHouseholdNmbr = 'inline';
    }
  }else{
    this.state.back_data = props.location.state;
  }
  this.onRespondentHandler = this.onRespondentHandler.bind(this);
  this.toggleHowSelected = this.toggleHowSelected.bind(this);
  this.toggleWork = this.toggleWork.bind(this);
}

componentDidMount() {
  document.title = "Nielsen Radio Ratings";
  window.addEventListener("popstate", () => {
   this.props.navigate(1);
 });
}

toggleHowSelected() {
   window.open('/SpHowSelected','','scrollbars=no,resizable=no,height=350,width=450');
}
toggleWork() {
   window.open('/SpWork','','scrollbars=no,resizable=no,height=350,width=450');
}


handleFieldChange =(e)=>{
 if (e.target.id === "title" && e.target.value !== "Other") {
   var pinfo1 = { ...this.state.pinfo,title_text: '' };
   this.setState({ pinfo:pinfo1},()=>{
     document.getElementById('title_text').value='';
   });
 }
 if (e.target.id === "title_text" ) {
   var pinfo2 = { ...this.state.pinfo,title: 'Other' };
   this.setState({ pinfo:pinfo2});
 }
}

formChange = (e) =>{
  var pinfo = { ...this.state.pinfo,[e.target.id]: e.target.value };
  this.setState({ pinfo:pinfo});
}

validPhone =(option)=>{
 switch (option){
   case "one":
   if(this.state.pinfo.phoneAreaCode.trim().length===0 || this.state.pinfo.phoneAreaCode===""
      || this.state.pinfo.phonePrefix==="" || this.state.pinfo.phonePrefix.trim().length===0
      || this.state.pinfo.phoneSufix==="" || this.state.pinfo.phoneSufix.trim().length === 0){
      return false;
    }
    else{
      return true;
    }
   case "two":
   if(this.state.pinfo.phoneAreaCode.trim().length!==3 || this.state.pinfo.phoneAreaCode===""
      || this.state.pinfo.phonePrefix==="" || this.state.pinfo.phonePrefix.trim().length!==3
      || this.state.pinfo.phoneSufix==="" || this.state.pinfo.phoneSufix.trim().length !== 4){
      return false;
    }else{
      let reg1 = RegExp(/^[0-9][0-9][0-9]$/i)
      let reg2 = RegExp(/^[0-9][0-9][0-9][0-9]$/i)
      return reg1.test(this.state.pinfo.phoneAreaCode) &&
             reg1.test(this.state.pinfo.phonePrefix) &&
             reg2.test(this.state.pinfo.phoneSufix);
    }

   default:
    return true;
 }

}

validZip = () =>{
 if(this.state.pinfo.addrZip === '' | this.state.pinfo.addrZip.trim().length!==5)
  {
    return false;
  }
  else
  {
    let reg = RegExp(/^[0-9]{5}$/i);
    return reg.test(this.state.pinfo.addrZip);
  }
}

validate = () =>{
  var valid = true;
  if(!this.state.pinfo.addrStreet1){
    this.setState({
      errorAddress: 'inline',
    });
    valid = false;
  }else{
    this.setState({
      errorAddress: 'none',
    });
  }
  if(!this.state.pinfo.addrCity){
    this.setState({
      errorCity: 'inline',
    });
    valid = false;
  }else{
    this.setState({
      errorCity: 'none',
    });
  }
  if(!this.state.pinfo.addrState){
    this.setState({
      errorState: 'inline',
    });
    valid = false;
  }else{
    this.setState({
      errorState: 'none',
    });
  }
  if(!this.state.pinfo.addrZip){
    this.setState({
      errorZip: 'inline',
    });
    valid = false;
  }else if(!this.validZip()){
    this.setState({
      errorZip: 'inline',errorInvalidZip: 'inline',
    });
    valid = false;
  }else{
    this.setState({
      errorZip: 'none',errorInvalidZip: 'none',
    });
  }
  if(!this.validPhone("one")){
    this.setState({
      errorPhone: 'inline',
    });
    valid = false;
  }else if(!this.validPhone("two")){
    this.setState({
      errorPhone: 'inline',errorInvalidPhone: 'inline',
    });
    valid = false;
  }else{
    this.setState({
      errorPhone: 'none',errorInvalidPhone: 'none',
    });
  }
  if(!this.state.pinfo.networkAffiliate){
    this.setState({
      errorAffiliation: 'inline',
    });
    valid = false;
  }else{
    this.setState({
      errorAffiliation: 'none',
    });
  }
  if(!this.state.pinfo.householdNum){
    this.setState({
      errorHouseholdNmbr: 'inline',
    });
    valid = false;
  }else{
    this.setState({
      errorHouseholdNmbr: 'none',
    });
  }
  if (!valid){
    this.setState({
      error:'',
    });
    return false;
  }else{
    this.setState({
      error:'none',errorPhoneNmbrMismatch:'none', pPHConfirm:'none',errorHouseholdNmbr : 'none'
    });
    return true;
  }
}

 autoTab=(e,destination)=>{
   if ((e.target.id === 'phoneAreaCode' || e.target.id === 'phonePrefix' )&& e.target.value.length===e.target.maxLength)
   {
     if (destination === 'phonePrefix'){
       document.getElementById("phonePrefix").focus();
       document.getElementById("phonePrefix").select();
     }
     else{
       document.getElementById("phoneSufix").focus();
       document.getElementById("phoneSufix").select();
     }
   }
 }
 back = (e) =>{
     this.props.navigate("/SpEligibility",{replace:true , state:this.state.back_data});
 }

onRespondentHandler = (e) => {
  e.preventDefault();
  if(this.validate()) {
   const apiName = 'econsent';
   const path = 'econsent/response';
   const myInit = {
     headers: {
      'headerauth1':   window.sessionStorage.getItem('token')
    },
     body: {
       'hh_param': JSON.parse(window.sessionStorage.getItem('hh_param')),
       'pinfo': this.state.pinfo,
       'action':'pinfo'
     },
   };
   API.post(apiName, path, myInit)
     .then(res => {
         this.setState({hh_param:res.form_data})
         window.sessionStorage.removeItem('hh_param');
         window.sessionStorage.setItem('token',res.token)
        window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
        // if return phone number mismatch
        if(res.form_data.nextPage === 'errorPhoneNmbrMismatch'){
          this.setState({errorPhoneNmbrMismatch:'inline',errorPhone:'inline'})
        }else {
             Auth.login(() => {
               if (res.form_data.nextPage ===  'PPH_CONFIRM'){
                 this.props.navigate("/SpPhConfirm",{replace:true,state:this.state});}
               // if (res.form_data.nextPage ===  'SYS_ERROR'){
               //   this.props.navigate("/SpSysError",{replace:true});}
               // if(res.form_data.nextPage === 'SESSION_EXPIRED'){
               //   this.props.navigate("/SessionExpired",{replace:true});}
               // if(res.form_data.nextPage === 'PHONE_LOCKOUT'){
               //   this.props.navigate("/SpPhoneLockOut",{replace:true});}
               // if (res.form_data.nextPage ===  'AFFILIATED'){
               //   this.props.navigate("/SpAffiliated",{replace:true});}
               // if (res.form_data.nextPage ===  'HOUSEHOLD_NINE_PLUS'){
               //   this.props.navigate("/SpNinePlus",{replace:true});}
                });
           }
       })
       .catch(error => {
         const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
         this.setState({ hh_param});
       });

    }else{
      console.log('Invalid Form');
    }
  }


  render(){
    const {pinfo} = this.state;
    const {nextPage} = this.state.hh_param;

    if (nextPage ===  'SYS_ERROR'){
      return(<SpSysError/>)}
    if(nextPage === 'SESSION_EXPIRED'){
      return(<SessionExpired/>)}
    if(nextPage === 'PHONE_LOCKOUT'){
      return(<SpPhoneLockOut/>)}
    if (nextPage ===  'AFFILIATED'){
      return(<SpAffiliated/>)}
    if (nextPage ===  'HOUSEHOLD_NINE_PLUS'){
      return(<SpNinePlus/>)}
    if (nextPage ===  'ALREADYPART'){
      return(<SpAlreadyPart/>)}

  return (
    <div>
    <Form name="myform" onSubmit={this.onRespondentHandler} autoComplete="off">
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
    <SpHeader screen="" image="bePart"/>

    <tr>
    <td width="1"  valign="top" rowSpan="2">&nbsp;</td>
    <td colSpan="3" valign="top">
      <br></br>
  	  <Table width="400" border="0">
      <tbody>
  	   <tr>
  	    <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
  	     <td width="1">&nbsp;</td>
  	     <td><p> <b>Complete su nombre y direcci&oacute;n, para que podamos enviarle los materiales de la encuesta.</b></p></td>
  	   </tr>

  	   <tr>
  	    <td colSpan="2"></td>
  	    <td>
  	     <Table width="450" border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr valign="top">
              <td align="right" height="25"><div align="right">T&iacute;tulo:&nbsp;</div></td>
              <td>&nbsp;&nbsp;&nbsp;</td>
              <td><input type="radio" id="title" name="title" value="Mr." checked={pinfo.title === "Mr."} onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Sr.{' '}
              <input type="radio" id="title" name="title" value="Mrs." checked={pinfo.title === "Mrs."}   onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Sra.{' '}
              <input type="radio" id="title" name="title" value="Miss" checked={pinfo.title === "Miss"}  onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Srta.{' '}
              <br/>
              <input type="radio" id="title" name="title" checked={pinfo.title === "Other"} value="Other"  onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Otro:{' '}
              <input type="text" name="title_text" id="title_text" pattern="[a-zA-Z0-9-_.,\s]*" size="10" maxLength="8" value={pinfo.title_text||''}  onInput={this.formChange} onBlur={this.handleFieldChange} />
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">Nombre:&nbsp;</div>
              </td>
              <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="firstName" id="firstName" pattern="[a-zA-Z0-9-_\s]*" size="30" maxLength="20" value={pinfo.firstName||''}  onChange={this.formChange}/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">Apellido:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="lastName" id="lastName" pattern="[a-zA-Z0-9-_\s]*" size="30" maxLength="21" value={pinfo.lastName||''}  onChange={this.formChange}/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td height="20" colSpan="3">
                <div align="right"></div>
              </td>
            </tr>
            <tr valign="top">
              <td >
                <div align="right">*&nbsp;Calle:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td nowrap="true" >
                <div align="left">
                  <input type="text" name="addrStreet1" pattern="[a-zA-Z0-9-_,.#\s]*" id="addrStreet1" size="30" maxLength="50" value={pinfo.addrStreet1||''}   onChange={this.formChange}/>
          	<img style={{display:this.state.errorAddress}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td >
                <div align="right">N&uacute;mero&nbsp;de&nbsp;apartamento:</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="addrApt" id="addrApt" pattern="[a-zA-Z0-9-_,.#\s]*"  size="30" maxLength="14" value={pinfo.addrApt||''}  onChange={this.formChange}/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td >
                <div align="right">*&nbsp;Ciudad:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td nowrap="true" >
                <div align="left">
                  <input type="text" name="addrCity" id="addrCity" pattern="[a-zA-Z0-9-_,.#\s]*"  size="30" maxLength="30" value={pinfo.addrCity||''}  onChange={this.formChange}/>

          	<img style={{display:this.state.errorCity}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>

                </div>
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">*&nbsp;Estado:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <select name="addrState" id="addrState" value={pinfo.addrState||'--'}  onChange={this.formChange}>
                    <option value="--">&nbsp;</option>
                    <option  value="AL">Alabama</option>
                    <option  value="AK">Alaska</option>
                    <option  value="AZ">Arizona</option>
                    <option  value="AR">Arkansas</option>
                    <option  value="CA">California</option>
                    <option  value="CO">Colorado</option>
                    <option  value="CT">Connecticut</option>
                    <option  value="DE">Delaware</option>
                    <option  value="DC">District of Columbia</option>
                    <option  value="FL">Florida</option>
                    <option  value="GA">Georgia</option>
                    <option  value="HI">Hawaii</option>
                    <option  value="ID">Idaho</option>
                    <option  value="IL">Illinois</option>
                    <option  value="IN">Indiana</option>
                    <option  value="IA">Iowa</option>
                    <option  value="KS">Kansas</option>
                    <option  value="KY">Kentucky</option>
                    <option  value="LA">Louisiana</option>
                    <option  value="ME">Maine</option>
                    <option  value="MD">Maryland</option>
                    <option  value="MA">Massachusetts</option>
                    <option  value="MI">Michigan</option>
                    <option  value="MN">Minnesota</option>
                    <option  value="MS">Mississippi</option>
                    <option  value="MO">Missouri</option>
                    <option  value="MT">Montana</option>
                    <option  value="NC">North Carolina</option>
                    <option  value="ND">North Dakota</option>
                    <option  value="NE">Nebraska</option>
                    <option  value="NV">Nevada</option>
                    <option  value="NH">New Hampshire</option>
                    <option  value="NJ">New Jersey</option>
                    <option  value="NM">New Mexico</option>
                    <option  value="NY">New York</option>
                    <option  value="OH">Ohio</option>
                    <option  value="OK">Oklahoma</option>
                    <option  value="OR">Oregon</option>
                    <option  value="PA">Pennsylvania</option>
                    <option  value="RI">Rhode Island</option>
                    <option  value="SC">South Carolina</option>
                    <option  value="SD">South Dakota</option>
                    <option  value="TN">Tennessee</option>
                    <option  value="TX">Texas</option>
                    <option  value="UT">Utah</option>
                    <option  value="VT">Vermont</option>
                    <option  value="VA">Virginia</option>
                    <option  value="WA">Washington</option>
                    <option  value="WI">Wisconsin</option>
                    <option  value="WV">West Virginia</option>
                    <option  value="WY">Wyoming</option>
                  </select>

          	<img style={{display:this.state.errorState}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>

                </div>
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">*&nbsp;C&oacute;digo&nbsp;Postal:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="addrZip" id="addrZip" value={pinfo.addrZip||''}   pattern="[0-9]*"  onChange={this.formChange} size="5" maxLength="5"/>
          	<img style={{display:this.state.errorZip}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
          	<font style={{display:this.state.errorInvalidZip, color:'red'}}><b>El c&#243;digo postal no es v&#225;lido.</b></font>

          	</div>
              </td>
            </tr>
            <tr valign="top">
              <td colSpan="3" >
                <div align="right">&nbsp;</div>
              </td>
            </tr>
            <tr valign="top">
              <td width="130" height="25">
                <div align="right">*&nbsp;Tel&eacute;fono&nbsp;del&nbsp;hogar: </div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td >
                <div align="left">(<input type="text" name="phoneAreaCode" id="phoneAreaCode"  pattern="[0-9]*"  size="3" maxLength="3" value={pinfo.phoneAreaCode||''}   onChange={this.formChange} onKeyUp={(e)=>this.autoTab(e,'phonePrefix')}/>
                  {' '}){' '}
                  <input type="text" name="phonePrefix" id="phonePrefix" size="3"  pattern="[0-9]*"  maxLength="3"  value={pinfo.phonePrefix||''} onChange={this.formChange} onKeyUp={(e)=>this.autoTab(e,'phoneSufix')}/>
                  {' '}-<input type="text" name="phoneSufix" id="phoneSufix" size="4"  pattern="[0-9]*"  maxLength="4" value={pinfo.phoneSufix||''}  onChange={this.formChange}/>
          	<img style={{display:this.state.errorPhone}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
          <br/><div style={{display:this.state.errorInvalidPhone, color:'red'}}><b>El n&#250;mero de tel&#233;fono no es v&#225;lido.</b></div>
          	<div style={{display:this.state.errorPhoneNmbrMismatch, color:'red'}}><b>El tel&#233;fono que nos proporcion&#243; no coincide con el que tenemos registrado, por favor vuelva a ingresar su n&#250;mero de tel&#233;fono.</b></div>
             </div>
              </td>
            </tr>
            <tr valign="top">
              <td >&nbsp;</td>
              <td>&nbsp;</td>
              <td >No incluya tel&eacute;fonos celulares ni comerciales. <a href="#" onClick={this.toggleHowSelected}> C&oacute;mo
                  escogieron mi hogar?</a></td>
            </tr>

            <tr valign="top">
              <td >&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            </tbody>
  	  </Table>
  	  </td>
  	  </tr>
  	  <tr>
  	  <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
  	  <td>&nbsp;</td>
  	  <td><b>* &iquest;<a href="#" onClick={this.toggleWork}>Trabaja</a> usted o alguien en su casa para una estaci&oacute;n o red de radio, una estaci&oacute;n o red de televisi&oacute;n, o un sistema o red de cable?</b><br/>
              <input type="radio" name="networkAffiliate" id="networkAffiliate" checked ={pinfo.networkAffiliate === 'Y'}  onChange={this.formChange} value="Y"/>
              {' '}S&iacute;{' '}
              <input type="radio" name="networkAffiliate" id="networkAffiliate" value="N" checked ={pinfo.networkAffiliate === 'N'}  onChange={this.formChange} />
              {' '}No{' '}
          <img style={{display:this.state.errorAffiliation}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
               </td>
          </tr>
   	  <tr>
  	  <td colSpan="3">&nbsp;</td>
  	  </tr>
  	  <tr>
  	  <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
  	  <td>&nbsp;</td>
  	  <td><b>Para asegurarme de enviarle el n&uacute;mero correcto de cuadernitos necesito preguntarle lo siguiente: <em>Incluy&eacute;ndose usted
  	      </em>,
  	    &iquest;cu&aacute;ntas personas de 12 a&ntilde;os de edad o mayores viven en su casa?</b></td>
  	  </tr>
  	  	  <tr>
  	  <td colSpan="3">&nbsp;</td>
  	  </tr>
  	  <tr>
  	  <td colSpan="2">&nbsp;</td>
  	  <td>
  	  <Table border="0" width="434">
      <tbody>
              <tr>
              <td width="261" valign="middle" nowrap="true"  >
                <div align="left">*&nbsp;N&uacute;mero de personas de 12 a&ntilde;os o m&aacute;s: </div>
              </td>
  						 <td nowrap="true" >
                             <select name="householdNum" value ={pinfo.householdNum || '0'}  onChange={this.formChange} id="householdNum">
                    <option value="0">&nbsp;</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">M&aacute;s de 9</option>
                             </select>

          	<img style={{display:this.state.errorHouseholdNmbr}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>

          	<font style={{display:this.state.pPHConfirm, color:'red'}}><b>Por favor corrija.</b></font>
             </td>
              </tr>
              </tbody>
          </Table>		</td>
  	  </tr>
  	   <tr>
  	  <td colSpan="3">&nbsp;
  	  </td>
  	  </tr>

  	  <tr style={{display:this.state.error}}>
  	  	<td colSpan="2">&nbsp;</td>
  	  	<td><font style={{color:'red'}}><b>Estos campos no pueden estar vac&#237;os.  Respuestas son requisitas.</b></font>
  	  	</td>
  	  </tr>
      </tbody>
  	  </Table>
        <p align="left"></p>
        <p align="center"><button type="button" name="back" onClick={this.back}>Regresar</button>{' '}<input type="submit" name="continue_to_3" value="Continuar"/></p>
      </td>
    </tr>
  <tr>
    <td  width="433" colSpan="3">
      <SpLegalFooter/>
    </td>
  </tr>
  </tbody>

</Table>
  </Form>
    </div>
    );
}
}


export default WithRouter(SpPersonalInfo);
