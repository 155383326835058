import React from 'react';
import {Form, Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';
import Arrow2 from "../images/arrow2.jpg";
import { WithRouter } from "../components/WithRouter";
import { API } from 'aws-amplify';
import Auth from "../model/Auth";
import {EnSysError, SessionExpired, EnAlreadyPart, EnAffiliated, EnPhoneLockOut,EnNinePlus} from './';


class EnAgeGender  extends React.Component  {

  state = {
      hh_param:{
        sample_id: '',
        languageUsed:'EN',
        nextPage: '',
        original_token:'',
        frameCode:''
      },
     field_req:'none',
     errorList:''
   }

  constructor(props){
    super(props);

    this.state.hh_param = JSON.parse(window.sessionStorage.getItem('hh_param'));
    this.state.ageGender = {};
    if(props.location.state.ageGender){
      this.state = props.location.state;
    }else{
      this.state.back_data = props.location.state;
    }
    this.onRespondentHandler = this.onRespondentHandler.bind(this);
  }

  componentDidMount() {
    document.title = "Nielsen Radio Ratings";
 }

  formChange = (e) =>{
    const ageGender = {...this.state.ageGender,[e.target.id] : e.target.value}
    this.setState({ ageGender});
  }

 validate=()=>{
    const iHouseholdNum = this.state.hh_param.householdNum;
    const err = {};
    let err_conf = false;
    for (let i=0;i<iHouseholdNum;i++){
      let ag = '';
      let gg = '';
      if(i===0){
        ag = this.state.ageGender.age0;
        gg = this.state.ageGender.gender0;
      }else if(i===1){
        ag = this.state.ageGender.age1;
        gg = this.state.ageGender.gender1;
      }else if(i===2){
        ag = this.state.ageGender.age2;
        gg = this.state.ageGender.gender2;
      }else if(i===3){
        ag = this.state.ageGender.age3;
        gg = this.state.ageGender.gender3;
      }else if(i===4){
        ag = this.state.ageGender.age4;
        gg = this.state.ageGender.gender4;
      }else if(i===5){
        ag = this.state.ageGender.age5;
        gg = this.state.ageGender.gender5;
      }else if(i===6){
        ag = this.state.ageGender.age6;
        gg = this.state.ageGender.gender6;
      }else if(i===7){
        ag = this.state.ageGender.age7;
        gg = this.state.ageGender.gender7;
      }else if(i===8){
        ag = this.state.ageGender.age8;
        gg = this.state.ageGender.gender8;
      }else if(i===9){
        ag = this.state.ageGender.age9;
        gg = this.state.ageGender.gender9;
      }
      if(!ag || !gg ){
        err[i] = true;
        err_conf = true;
      }else{
        err[i] = false;
      }
    }
    if(err_conf){
      this.setState({errorList: err,field_req:'inline'});
      return false;
    }else{
        // clear any error State
      this.setState({field_req:'none'});
      window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));
      return true;
    }
  }
   ageGenderTable = () => {
     let items = [];
     const iHouseholdNum = this.state.hh_param.householdNum;
     for (let i=0; i < iHouseholdNum; i++)
      {
       var personNum = "" + (i+1);
       var labelAge = "age" + i;
       var labelGender = "gender" + i;

       items.push(<tr valign="middle">
         <td bgcolor="#FFFFFF">
         {i === 0 &&
           <div align="center"><b>You</b></div>}
           {i !== 0 && <div align="center"><b>Household Member #{personNum}</b></div>}
       </td>
       <td>
         <div align="center">
           <select name={labelAge} id={labelAge} onChange={this.formChange} defaultValue={this.state.ageGender[labelAge]||''}>
               <option value=""></option>

               {i === 0 &&
                   <option value="16">16-17</option>
               }
               { i !== 0 &&
                 <option value="15">12-17</option>
               }
               <option value="21">18-24</option>
               <option value="29">25-34</option>
               <option value="39">35-44</option>
               <option value="47">45-49</option>
               <option value="52">50-54</option>
               <option value="59">55-64</option>
               <option value="70">65+</option>
               <option value="0">Refused</option>
           </select>
         </div>
       </td>
       <td>
         <div align="center">
           <select name={labelGender} id={labelGender} onChange={this.formChange} defaultValue={this.state.ageGender[labelGender]||''}>
             <option value="" >&nbsp;</option>
             <option value="M">Male</option>
             <option value="F">Female</option>
             <option value="R">Refused</option>
           </select>
         </div>
       </td>
     </tr>)
   }
    return items;
   }

   errorList = () => {
     const errorList = this.state.errorList;
     let error = [];
     for (let i=0;i<this.state.hh_param.householdNum;i++)
     {
         if(errorList && errorList[i]){
           error.push(<tr height="11">
                 <td nowrap valign="center">&nbsp;<img src={Arrow2} width="36" height="18" border="0" alt="arrow"/></td>
               </tr>)
         }else{
           error.push(<tr height="11">
               <td nowrap>&nbsp;</td>
             </tr>)
         }
     }
   return error;
 }

 back = (e) =>{
   Auth.login(()=>{
     this.props.navigate("/EnPhConfirm",{replace:true , state:this.state.back_data});
   });
 }

  onRespondentHandler = (e) => {
    e.preventDefault();
    if(this.validate()) {
     const apiName = 'econsent';
     const path = 'econsent/response';
     const myInit = {
       headers: {
        'headerauth1':   window.sessionStorage.getItem('token')
      },
       body: {
         'hh_param': JSON.parse(window.sessionStorage.getItem('hh_param')),
         'ageGender':this.state.ageGender,
         'action':'age_gender'
       },
     };
     API.post(apiName, path, myInit)
       .then(res => {
           this.setState({hh_param:res.form_data})
           // first remove the storage before setting the response
           window.sessionStorage.removeItem('hh_param');
           window.sessionStorage.setItem('token',res.token)
           window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
             Auth.login(() => {
               if (res.form_data.nextPage === 'RACE'){
                  this.props.navigate("/EnRace",{replace:true , state:this.state});}
            });

           // if return is field empty
           if(res.form_data.nextPage === 'field_req'){
             this.setState({field_req:'inline'})
           }
       })
       .catch(error => {
         const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
         this.setState({ hh_param});
       });

    }else{
      console.log('Invalid Form', this.state);
    }
  }

  render(){
    const {nextPage} = this.state.hh_param;
    if (nextPage ===  'SYS_ERROR'){
      return(<EnSysError/>)}
    if(nextPage === 'SESSION_EXPIRED'){
      return(<SessionExpired/>)}
    if (nextPage ===  'AFFILIATED'){
      return(<EnAffiliated/>)}
    if (nextPage ===  'HOUSEHOLD_NINE_PLUS'){
      return(<EnNinePlus/>)}
    if (nextPage ===  'PHONE_LOCKOUT'){
      return(<EnPhoneLockOut/>)}
    if (nextPage ===  'ALREADYPART'){
      return(<EnAlreadyPart/>)}



  return (
    <div>
    <Form onSubmit = {this.onRespondentHandler}>
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
    <Header screen="" image="bePart"/>

  <tr>
    <td width="19" height="411" valign="top" rowSpan="2"></td>
    <td width="1" height="411" valign="top" rowSpan="2"></td>
    <td width="450" height="205" colSpan="3" valign="top">
      <p>&nbsp;</p>
      {this.state.hh_param.householdNum < 2 && <p><b> * To make sure we represent all ages and genders in your area, please use the drop-down boxes below to tell us your age (range) and gender.</b></p>
     }
     {this.state.hh_param.householdNum > 1 && <p><b>* To make sure we represent all people in your area, we'd like to ask you about the age and gender of all members of your household age 12 or older. </b></p>
      }
      {this.state.hh_param.householdNum > 1 && <p><b> Starting with yourself, please select the correct age range and gender for each household member in the table below.</b></p>}
      <div align="left">
     <Table width="450" border="0"  cellSpacing="0" cellPadding="0">
     <tbody>
     <tr>
     	<td width="410" rowSpan={this.state.hh_param.householdNum+1} height={this.state.hh_param.householdNum*10}>
        <Table width="410" border="1" cellSpacing="0" cellPadding="2" bordercolor="#CCCCCC">
          <tbody>
          <tr valign="middle" align="center" bgcolor="#FFFFCC">
            <td width="300" height="10">
              <div align="center" nowrap="true"><font color="#000066"><b>Household Member</b></font></div>
            </td>
            <td height="10">
              <div align="center"><font color="#000066"><b>Age</b></font></div>
            </td>
            <td height="10">
              <div align="center"><font color="#000066"><b>Gender</b></font></div>
            </td>
          </tr>
          {this.ageGenderTable()}

        </tbody>
      </Table>
      </td>
      <td height="11">&nbsp;</td>
      </tr>
        {this.errorList()}
    </tbody>
    </Table>

        <p><font style={{display:this.state.field_req, color:"red"}}>&nbsp;<b>Fields cannot be blank. Answers are required.</b></font></p>

    </div>
    <p align="center"> <br/>
      <button type="button" name="back" onClick={this.back}>Previous</button>{' '}<input type="submit" name="Continue_to_4" value="Continue"/></p>
    </td>
</tr>
  <tr>
    <td  width="433" colSpan="3">
      <EnLegalFooter/>
    </td>
  </tr>
  </tbody>
</Table>
</Form>
    </div>
    );
}
}


export default WithRouter(EnAgeGender);
