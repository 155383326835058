import React from 'react';
import {  Table} from 'react-bootstrap';
import EnCopyRights from '../components/EnCopyRights';

import "../css/econsent.css";

class EnOrigin extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }
  componentDidMount() {
    document.title = "Cultural Origin";
  }

  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }

  render(){
    return (
      <div align="center">
  <Table cellSpacing="0" cellPadding="0" border="0">
  <tbody>
    <tr>
      <td height="40" valign="top" align="left" width="20" rowSpan="2">
      </td>
      <td height="12" valign="middle" align="left">&nbsp; </td>
      <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
    </tr>
    <tr>
      <td height="30" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
        to close window.</a></td>
    </tr>
    <tr>
      <td valign="top" height="210"></td>
      <td width="410" height="210" valign="top" align="left">
        <p ><span className="headline">What
          is meant by &quot;Spanish, Hispanic or Latino&quot;?</span></p>
        <p >In order to make
        sure the ratings are reliable, we need to include all groups of people.
        If you or someone in your household is Spanish, Hispanic or Latino,
        please let us know by checking the appropriate box. Examples of Spanish, Hispanic
        or Latino include Mexican, Mexican-American, Chicano, Puerto Rican, Cuban,
        or another Spanish, Hispanic or Latino group.</p>
        <br/>
        <p><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
          to close window.</a></p>
      </td>
      <td height="150" valign="top" align="left">&nbsp;</td>
    </tr>
    <tr>
      <td valign="top" height="20"></td>
      <td width="408" valign="top">
      <EnCopyRights/>
      </td>
      <td valign="top">&nbsp;</td>
    </tr>
    </tbody>
  </Table>
</div>
    );
}
}

export default EnOrigin;
