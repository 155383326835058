import { useNavigate, useLocation } from 'react-router-dom';

export const WithRouter = (Component) => {
  const Wrapper = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
      <Component
        navigate={navigate}
        {...props}
        location = {location}
        />
    );
  };

  return Wrapper;
};
