import React from 'react';
import { Table } from 'react-bootstrap';
import '../css/default.css';
import MailpiecesShort from '../images/mailpiecesShort.jpg';
import EnCopyRights from '../components/EnCopyRights';

class EnWhatsComing extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }
  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }

  componentDidMount() {
    document.title = "What's coming in the mail?";
  }

  render(){
  return (
    <div align="center">
<Table cellSpacing="0" cellPadding="0" border="0" style={{marginLeft:"5px"}}>
<tbody>
  <tr>
    <td height="40" valign="top" align="left" width="20" rowSpan="2">

    </td>
    <td height="12" valign="middle" align="left">&nbsp; </td>
    <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
  </tr>
  <tr>
    <td height="20" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
      to close window.</a></td>
  </tr>
  <tr>
    <td valign="top" height="210"></td>
    <td width="410" height="210" valign="top" align="left">
      <p><span className="headline">What's
            coming in the mail?</span></p>
            <p >If you’ve already agreed to participate, you’ll soon be receiving a colorful box just like the one pictured below. This box will contain materials you need to let radio stations know what you listen to.</p>
        <p>
        <img src= {MailpiecesShort} alt="" height="260" width="420"/>
        </p>
        <p style={{marginBottom:'0px'}}><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
          to close window.</a></p>
        </td>
        <td height="210" valign="top" align="left">&nbsp;</td>
        </tr>
        <tr>
        <td valign="top" height="20"></td>
        <td width="408" valign="top">
        <EnCopyRights/>
        </td>
        <td valign="top">&nbsp;</td>
        </tr>
        </tbody>
        </Table>
        </div>
  );
}
}

export default EnWhatsComing;
