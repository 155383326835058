import React, {useEffect} from 'react';
import {Table} from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnThreeTrys = (props) => {
  const navigate = useNavigate();
    useEffect(() => {
      document.title = 'Nielsen Radio Ratings';
      window.addEventListener("popstate", () => {
       navigate(1);
     });
    });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
        <Header image="bePart" screen=""/>

  <tr>
    <td width="19"  valign="top"></td>
    <td width="450" colSpan="2" valign="top">
        <p>&nbsp;</p>
        <p >We're sorry, the serial number you entered does not match
        our records, please call 1-800-204-3174.</p><p>&nbsp;</p>

</td>
</tr>

        <EnLegalFooter/>
        </tbody>
</Table>
</div>
    );
};


export default EnThreeTrys;
