const config = {
  API: {
    endpoints: [
      {
        name: "econsent",
        endpoint: "https://eopjqoiy86.execute-api.us-east-1.amazonaws.com/prod/"
      }
    ]
  }

}
export default config;
