import React from 'react';
import {  Table} from 'react-bootstrap';
import EnCopyRights from '../components/EnCopyRights';

import "../css/econsent.css";

class EnHowSelected extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }
  componentDidMount() {
    document.title = "How was my household selected?";
  }

  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }

  render(){
    return (
      <div align="center">
  <Table cellSpacing="0" cellPadding="0" border="0">
  <tbody>
    <tr>
      <td height="40" valign="top" align="left" width="20" rowSpan="2">
      </td>
      <td height="12" valign="middle" align="left">&nbsp; </td>
      <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
    </tr>
    <tr>
      <td height="30" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
        to close window.</a></td>
    </tr>
    <tr>
      <td valign="top" height="210"></td>
      <td width="410" height="210" valign="top" align="left">
        <p align="left"><span className="headline">How was my household selected?</span></p>
        <p align="left">Nielsen Radio Ratings gathers the listening of people ages 12 and older in the U.S. Since we cannot talk to every person, we pick a random sample of households to speak for the entire population. A computer selects households by picking phone numbers at random, much as winning lottery numbers are selected. We are just as likely to call unlisted phone numbers as listed. Your participation ensures that the radio ratings represent the people of your community.</p>
        <br/>
        <p><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
          to close window.</a></p>
      </td>
      <td height="210" valign="top" align="left">&nbsp;</td>
    </tr>
    <tr>
      <td valign="top" height="20"></td>
      <td width="408" valign="top">
      <EnCopyRights/>
      </td>
      <td valign="top">&nbsp;</td>
    </tr>
    </tbody>
  </Table>
</div>
    );
}
}

export default EnHowSelected;
