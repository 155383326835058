import React  from 'react';
import { Table,Form } from 'react-bootstrap';
import { API } from 'aws-amplify';

import SpHeader from '../components/SpHeader';
import SpLegalFooter from '../components/SpLegalFooter';
import { WithRouter } from "../components/WithRouter";
import Auth from "../model/Auth";

import Buttonsstacked2pinksp from "../images/buttonsstacked2pinksp.gif";
import Buttonsname from "../images/buttonsname_sp.gif";
import Buttonsmail from "../images/buttonsmail_sp.gif";
import Arrow2 from "../images/arrow2.jpg";

import {SpMisType, SpInProgress, SpAffiliated, SpAlreadyPart, SpDeadlinePast,SpNinePlus,SpNotCellOnly,SpPhoneLockOut,SpSysError,SpTeenLockOut,SpThreeTrys,SessionExpired} from './';



class SpHome extends React.Component {

  state = {
     hh_param:{
       sample_id: '',
       languageUsed:'SP',
       nextPage: '',
       iBadLoginCount:0,
       frameCode:'',
       original_token:''
     },
     mapImage:Buttonsstacked2pinksp,
     sampId_req:'none'
   }

   constructor(props){
     super(props);
     let badCount = 0;
     if( JSON.parse(window.sessionStorage.getItem('hh_param')) !== null){
       badCount = JSON.parse(window.sessionStorage.getItem('hh_param'))['iBadLoginCount'];
     }
     this.state.hh_param = {'sample_id':'','languageUsed':'SP','nextPage':'','iBadLoginCount':badCount,'original_token':'','frameCode':''};
     window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));

     this.formChange = this.formChange.bind(this);
     this.onLoginHandler = this.onLoginHandler.bind(this);
     this.toggleHowSelected = this.toggleHowSelected.bind(this);
     this.toggleWhatsComing = this.toggleWhatsComing.bind(this);
   }

   componentDidMount() {
     document.title = "Bienvenidos a Nielsen";
     document.getElementById('sample_id').focus();
     window.addEventListener("popstate", () => {
      this.props.navigate(1);
    });
  }

  toggleHowSelected() {
      window.open('/SpHowSelected','','scrollbars=no,resizable=no,height=300,width=450');
  }

  toggleWhatsComing() {
  window.open('/SpWhatsComing','','scrollbars=no,resizable=no,height=450,width=450');
  }

  formChange = (e) =>{
    const hh_param = { ...this.state.hh_param,[e.target.id]: e.target.value.trim() };
    this.setState({ hh_param});
  }

    validate(){
      if(!this.state.hh_param.sample_id){
        this.setState({
          sampId_req: 'inline',
        });
        return false;
      }else{
        window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
        return true;
      }
    }

    onLoginHandler = (e) => {
      e.preventDefault();
      if(this.validate()) {
       const apiName = 'econsent';
       const path = 'econsent/login';
       const myInit = {
         headers: {
          'headerauth1':   window.sessionStorage.getItem('token') || ''
        },
         queryStringParameters: {
           'form_data': window.sessionStorage.getItem('hh_param'),
         },
       };
       API.get(apiName, path, myInit)
         .then(res => {
            this.setState({hh_param:res.form_data});
             window.sessionStorage.setItem('token',res.token);
            window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));
               Auth.login(() => {
                 // if (res.form_data.nextPage === 'MISTYPE'){
                 //    this.props.navigate("/SpMisType",{replace:true});}
                 //  if (res.form_data.nextPage ===  'THREE_TRYS'){
                 //    this.props.navigate("/SpThreeTrys",{replace:true});}
                 //  if (res.form_data.nextPage ===  'DEADLINE_PAST'){
                 //    this.props.navigate("/SpDeadlinePast",{replace:true});}
                 //  if (res.form_data.nextPage ===  'ALREADYPART'){
                 //    this.props.navigate("/SpAlreadyPart",{replace:true});}
                 //  if (res.form_data.nextPage ===  'AFFILIATED'){
                 //    this.props.navigate("/SpAffiliated",{replace:true});}
                 //  if (res.form_data.nextPage ===  'HOUSEHOLD_NINE_PLUS'){
                 //    this.props.navigate("/SpNinePlus",{replace:true});}
                 //  if (res.form_data.nextPage ===  'PHONE_LOCKOUT'){
                 //    this.props.navigate("/SpPhoneLockOut",{replace:true});}
                 //  if (res.form_data.nextPage ===  'TEEN_LOCKOUT'){
                 //    this.props.navigate("/SpTeenLockOut",{replace:true});}
                 //  if (res.form_data.nextPage ===  'NOT_CELL_ONLY'){
                 //    this.props.navigate("/SpNotCellOnly",{replace:true});}
                 //  if (res.form_data.nextPage ===  'SYS_ERROR'){
                 //    this.props.navigate("/SpSysError",{replace:true});}
                  if (res.form_data.nextPage === 'ELIGIBILITY'){
                    this.props.navigate("/SpEligibility",{replace:true});}
                 //  if(res.form_data.nextPage === 'SESSION_EXPIRED'){
                 //    this.props.navigate("/SessionExpired",{replace:true});}

                  });

             // if return is sampid empty
             if(res.form_data.nextPage === 'sampId_req'){
               this.setState({sampId_req:'inline'})
             }
         })
         .catch(error => {
           const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
           this.setState({ hh_param});
         });


      }else{
        console.log('Invalid Form');
      }
    }



render(){
  const { mapImage,sampId_req, hh_param} = this.state;
  if (hh_param.nextPage === 'MISTYPE'){
     return(<SpMisType/>)}
   if (hh_param.nextPage ===  'THREE_TRYS'){
     return(<SpThreeTrys/>)}
   if (hh_param.nextPage ===  'DEADLINE_PAST'){
     return(<SpDeadlinePast/>)}
   if (hh_param.nextPage ===  'ALREADYPART'){
     return(<SpAlreadyPart/>)}
   if (hh_param.nextPage ===  'AFFILIATED'){
     return(<SpAffiliated/>)}
   if (hh_param.nextPage ===  'HOUSEHOLD_NINE_PLUS'){
     return(<SpNinePlus/>)}
   if (hh_param.nextPage ===  'PHONE_LOCKOUT'){
     return(<SpPhoneLockOut/>)}
   if (hh_param.nextPage ===  'TEEN_LOCKOUT'){
     return(<SpTeenLockOut/>)}
   if (hh_param.nextPage ===  'NOT_CELL_ONLY'){
     return(<SpNotCellOnly/>)}
   if (hh_param.nextPage ===  'SYS_ERROR'){
     return(<SpSysError/>)}
   if (hh_param.nextPage === 'IN_PROGRESS'){
     return(<SpInProgress sample_id = {hh_param.sample_id}/>)}
   if(hh_param.nextPage === 'SESSION_EXPIRED'){
     return(<SessionExpired/>)}

    return (
      <div>
      <Table cellSpacing="0" cellPadding="0" border="0">
      <tbody>

      <SpHeader screen="home" image="bePartNielsenTitle"/>

<tr><td align="left" >&nbsp;</td></tr>
<tr>
  <td width="19" height="411" valign="top" rowSpan="2"></td>
  <td width="1" height="411" valign="top" rowSpan="2"></td>
  <td width="450" height="205" colSpan="3" valign="top">
      <p><img className="mapImage" src={mapImage} alt="map" width="102" height="92" align="right" useMap="#Map" border="0" name="Image1"/>
      <map name="Map">
                  <area alt="up map" shape="rect" coords="-2,0,96,33" onClick={this.toggleHowSelected} onMouseOut={() => {this.setState({mapImage: Buttonsstacked2pinksp})}} onMouseOver={() => {this.setState({mapImage: Buttonsname})}}/>
                  <area alt="down map" shape="rect" coords="1,40,95,73" onClick={this.toggleWhatsComing} onMouseOut={() => {this.setState({mapImage: Buttonsstacked2pinksp})}} onMouseOver={() => {this.setState({mapImage: Buttonsmail})}}/>
                </map>
                Por m&aacute;s de 50 a&ntilde;os, Nielsen ha sido una proveedora l&iacute;der de informaci&oacute;n de medios de comunicaci&oacute;n y marketing, incluyendo los Ratings de Radio y de Televisi&oacute;n de Nielsen. Medimos lo que el p&#250;blico escucha en todo el pa&#237;s. Producimos los informes de ratings de radio para distintas ciudades en todo el pa&#237;s. Las estaciones de radio utilizan los estudios de Nielsen para tomar decisiones importantes sobre lo que usted oye en la radio. Todas las semanas escogemos algunos hogares en su &#225;rea para que participen en los ratings de radio. Es f&#225;cil, toma solo unos minutos cada d&#237;a y le enviaremos un peque&#241;o regalo en efectivo como muestra de nuestro agradecimiento.</p>
      <p>Para comenzar, ingrese el n&#250;mero de serie de 10 d&#237;gitos impreso sobre su nombre y direcci&#243;n en la tarjeta postal o en el sobre de la tarjeta, o use el n&#250;mero de 10 d&#237;gitos que aparece en su carta.</p>
      <Form onSubmit={this.onLoginHandler} autoComplete="off">

          <p>N&uacute;mero de serie: {' '}
            <input autoFocus type="text" pattern="[a-zA-Z0-9]*" onChange={this.formChange} defaultValue={''} name="sample_id" id="sample_id" maxLength="10" size="10"/>

            <img style={{display:sampId_req}} src={Arrow2} width="36" height="18" border="0"alt=""/>
            <font style={{display:sampId_req, color:"red"}} ><b>Por favor entre su n&uacute;ero de serie.</b></font></p>


          <p align="center">
            <input type="submit" name="Submit" value="Enviar"/></p>


        </Form>
          <p align="left">
            Esperamos contar con usted para que participe. Si tiene alguna pregunta, ll&#225;menos gratis al 1-800-204-3174. O <a href="http://nielsenradiodiary.com/sp_mainhome.htm" rel="noreferrer noopener" target="_blank">haga clic</a> aqu&#237; para visitar nuestro sitio web para m&#225;s informaci&#243;n.
          </p>
          </td>
        </tr>
        <tr>
          <td colSpan="3">
      <SpLegalFooter/>
      </td>
    </tr>
    </tbody>
    </Table>

            </div>
    );
  }
}

export default WithRouter(SpHome);
