import React from 'react';

const SpCopyRights = () => {
    return (

            <p className="verysmall" >Copyright &copy; {(new Date().getFullYear())} The Nielsen Company (US), LLC. Todos los derechos reservados.</p>


    );
};

export default SpCopyRights;
