import React from 'react';
import {Form, Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';
import Arrow2 from "../images/arrow2.jpg";
import { WithRouter } from "../components/WithRouter";
import { API } from 'aws-amplify';
import Auth from "../model/Auth";
import {EnSysError, SessionExpired,EnAlreadyPart,EnAffiliated, EnPhoneLockOut, EnNinePlus} from './';


class EnRace  extends React.Component  {

  state = {
      hh_param:{
        sample_id: '',
        languageUsed:'EN',
        nextPage: '',
        original_token:'',
        frameCode:''
      },
     error1824:'none',
     error2534:'none',
     raceOrigin:{
      household_men18to24:'',
      household_men25to34:'',
      household_spanish:'',
      household_race:''
     }
   }

  constructor(props){
    super(props);

    this.state.hh_param = JSON.parse(window.sessionStorage.getItem('hh_param'));
    this.state.ageGender = {};

      this.state.back_data = props.location.state;

    this.onRespondentHandler = this.onRespondentHandler.bind(this);
    this.toggleOrigin = this.toggleOrigin.bind(this);
  }

  componentDidMount() {
    document.title = "Nielsen Radio Ratings";
 }

 toggleOrigin() {
     window.open('/EnOrigin','','scrollbars=yes,resizable=yes,height=350,width=450');
 }

  formChange = (e) =>{
    const raceOrigin = {...this.state.raceOrigin,[e.target.name] : e.target.value}
    this.setState({ raceOrigin});
  }

  Question18to24 = () =>{
    let response = [];
    response.push(
      <tr>
		    <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
		    <td>&nbsp;</td>
		      <td nowrap="true"><b>*Are there any 18 to 24 year old men who currently live in your household?</b> <br/>
			        <input type="radio" name="household_men18to24" checked={this.state.raceOrigin.household_men18to24 === "Y"} onChange = {this.formChange} value="Y"/>Yes
			        <input type="radio" name="household_men18to24" checked={this.state.raceOrigin.household_men18to24 === "N"} onChange = {this.formChange} value="N"/>No
			        <input type="radio" name="household_men18to24" checked={this.state.raceOrigin.household_men18to24 === "D"} onChange = {this.formChange} value="D"/>Don't Know
			        <input type="radio" name="household_men18to24" checked={this.state.raceOrigin.household_men18to24 === "R"} onChange = {this.formChange} value="R"/>Refused
    	         <img  style={{display:this.state.error1824}} src={Arrow2} alt="arrow" width="36" height="18" border="0"/>
               <font style={{display:this.state.error1824, color:"red"}}><b>An answer is required.</b></font>
            </td>
	         </tr>
    )
    return response;
  }

 Question25to34 = () => {
   const res = [];
   res.push(
     <tr>
		<td valign="top"><b><font color="#000066"></font></b></td>
	  	<td>&nbsp;</td>
	  	<td nowrap="true"><b>*Are there any 25 to 34 year old men who currently live in your household?</b> <br/>
        		<input type="radio" name="household_men25to34" checked={this.state.raceOrigin.household_men25to34 === "Y"} onChange = {this.formChange} value="Y"/>Yes
        		<input type="radio" name="household_men25to34" checked={this.state.raceOrigin.household_men25to34 === "N"}  onChange = {this.formChange} value="N"/>No
			<input type="radio" name="household_men25to34" checked={this.state.raceOrigin.household_men25to34 === "D"} onChange = {this.formChange} value="D"/>Don't Know
			<input type="radio" name="household_men25to34" checked={this.state.raceOrigin.household_men25to34 === "R"}  onChange = {this.formChange} value="R"/>Refused
      <img  style={{display:this.state.error2534}} src={Arrow2} alt="arrow" width="36" height="18" border="0"/>
      <font style={{display:this.state.error2534, color:"red"}}><b>An answer is required.</b></font>

		</td>
	  </tr>
  )
    return res;
 }

 emptyRow = () =>{
   const res = [];
   res.push(<tr>
  <td colspan="3">&nbsp;</td>
  </tr>)
  return res;
 }

 validate=()=>{
   let valid = true;
    if(this.state.hh_param.YM1824 === '' && !this.state.raceOrigin.household_men18to24){
      this.setState({error1824:'inline'});
      valid=false;
    }else{
      this.setState({error1824:'none'});
    }
    if(this.state.hh_param.YM2534 === '' && !this.state.raceOrigin.household_men25to34){
      this.setState({error2534:'inline'});
      valid=false;
    }else{
      this.setState({error2534:'none'});
    }
    if(!valid){
      return false;
    }else{
        // clear any error State
      this.setState({error2534:'none',error1824:'none'});
      window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));
      return true;
    }
  }

 back = (e) =>{
   Auth.login(()=>{
     this.props.navigate("/EnAgeGender",{replace:true , state:this.state.back_data});
   });
 }

  onRespondentHandler = (e) => {
    e.preventDefault();
    if(this.validate()) {
     const apiName = 'econsent';
     const path = 'econsent/response';
     const myInit = {
       headers: {
        'headerauth1':   window.sessionStorage.getItem('token')
      },
       body: {
         'hh_param': JSON.parse(window.sessionStorage.getItem('hh_param')),
         'raceOrigin':this.state.raceOrigin,
         'action':'race_origin'
       },
     };
     API.post(apiName, path, myInit)
       .then(res => {
           this.setState({hh_param:res.form_data})
           // first remove the storage before setting the response
           window.sessionStorage.removeItem('hh_param');
           window.sessionStorage.setItem('token',res.token)
             Auth.login(() => {
               if (res.form_data.nextPage === 'THANK_YOU'){
                  this.props.navigate("/EnThankYou",{replace:true,state:this.state.hh_param.survey_date});}
            });

           // if return is field empty
           if(res.form_data.nextPage === 'field_req'){
             this.setState({field_req:'inline'})
           }
       })
       .catch(error => {
         const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
         this.setState({ hh_param});
       });

    }else{
      console.log('Invalid Form', this.state);
    }
  }

  render(){
    const {nextPage} = this.state.hh_param;
    if (nextPage ===  'SYS_ERROR'){
      return(<EnSysError/>)}
    if(nextPage === 'SESSION_EXPIRED'){
      return(<SessionExpired/>)}
    if (nextPage ===  'AFFILIATED'){
      return(<EnAffiliated/>)}
    if (nextPage ===  'HOUSEHOLD_NINE_PLUS'){
      return(<EnNinePlus/>)}
    if (nextPage ===  'PHONE_LOCKOUT'){
      return(<EnPhoneLockOut/>)}
    if (nextPage ===  'ALREADYPART'){
      return(<EnAlreadyPart/>)}



  return (
    <div>
    <Form onSubmit = {this.onRespondentHandler}>
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
      <Header screen="" image="bePart"/>

      <tr>
        <td width="21" valign="top" rowSpan="2">&nbsp;</td>
        <td width="3" valign="top">
        <p>&nbsp;</p>
        <Table width="100%" border="0">
          <tbody>
            { this.state.hh_param.YM1824 === '' && this.Question18to24() }
            { this.state.hh_param.YM1824 === '' && this.emptyRow() }
            { this.state.hh_param.YM2534 === '' && this.Question25to34() }
            { this.state.hh_param.YM2534 === '' && this.emptyRow() }
            <tr>
		          <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
		          <td>&nbsp;</td>
		          <td nowrap="true"><b>Is anyone in your household of{' '}
		            <a href="#" onClick={this.toggleOrigin}>Spanish</a>,{' '}
		            <a href="#" onClick={this.toggleOrigin}>Hispanic</a>, or{' '}
		            <a href="#" onClick={this.toggleOrigin}>Latino</a> origin? </b><br/>
			          <input type="radio" name="household_spanish" checked={this.state.raceOrigin.household_spanish === "Y"}  onChange = {this.formChange} value="Y"/>Yes
			          <input type="radio" name="household_spanish" checked={this.state.raceOrigin.household_spanish === "N"} onChange = {this.formChange} value="N"/>No
		          </td>
	          </tr>
	          <tr>
	  	        <td colSpan="3">&nbsp;</td>
	          </tr>
	          <tr>
	  	        <td valign="top"><b><font color="#000066"></font></b></td>
	  	        <td>&nbsp;</td>
	  	        <td nowrap="true"><b>Is anyone in your household Black or African American? </b><br/>
        		    <input type="radio" name="household_race" checked={this.state.raceOrigin.household_race === "Black"} onChange = {this.formChange} value="Black"/>Yes
        		    <input type="radio" name="household_race" checked={this.state.raceOrigin.household_race === "Other"} onChange = {this.formChange} value="Other"/>No
        	    </td>
	          </tr>
          </tbody>
        </Table>

    <p align="center"> <br/>
      <button type="button" name="back" onClick={this.back}>Previous</button>{' '}<input type="submit" name="Continue_to_4" value="Continue"/></p>
    </td>
</tr>
  <tr>
    <td  width="433" colSpan="3">
      <EnLegalFooter/>
    </td>
  </tr>
  </tbody>
</Table>
</Form>
    </div>
    );
}
}


export default WithRouter(EnRace);
