import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnInProgress = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });

  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <Header image="bePart" screen=""/>

  <tr>
    <td width="19"  valign="top"></td>
    <td width="450"colSpan="2" valign="top">
        <p>&nbsp;</p>
                <p > ***   '{props.sample_id}'  is not available for use!   ***</p>
        <p>&nbsp;</p>
        </td>
        </tr>

                <EnLegalFooter/>
        </Table>

    </div>
    );
  };



export default EnInProgress;
