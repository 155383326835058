import React, {useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import {Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';

const EnMisType = (props) => {
const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });

  const refresh=()=>{
    this.setState({nextPage:''})
  }

  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
      <tbody>
        <Header image="bePart" screen=""/>

  <tr>
    <td width="19"  valign="top"></td>
    <td width="450" height="205" colSpan="2" valign="top">

    <p><span className="headline">Did you mistype?</span></p>
    <p >It does not appear that your serial number
      matches one in our records as a possible survey participant. You may
      have mistyped it. If so, just choose the &#8220;back&#8221; button and
      try it again. To be eligible for our survey, your household should have
      received a postcard or a letter with your unique serial number. We thank
      you for your interest and hope that if we select your household in the
    future you will be just as willing to participate.</p>
    <p>&nbsp;</p>
    <p align="center" ><Link to="/"  onClick = {refresh} replace >Click here to try again.</Link></p>
    </td>
</tr>

        <EnLegalFooter/>
        </tbody>
</Table>
</div>
    );
};


export default EnMisType;
