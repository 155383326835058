import React from 'react';
import {Form, Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';
import Arrow2 from "../images/arrow2.jpg";
import { WithRouter } from "../components/WithRouter";
import { API } from 'aws-amplify';
import Auth from "../model/Auth";
import {EnTeenLockOut, EnSysError, SessionExpired,EnAlreadyPart,EnNinePlus, EnPhoneLockOut, EnAffiliated} from './';


class EnEligibility  extends React.Component  {

  state = {
      hh_param:{
        sample_id: '',
        languageUsed:'EN',
        nextPage: '',
        original_token:'',
        frameCode:''
      },
     field_req:'none',
     ofAge: ''
   }

  constructor(props){
    super(props);
    if(props.location.state){
      this.state = props.location.state;
    }
    this.state.hh_param = JSON.parse(window.sessionStorage.getItem('hh_param'));
    this.onRespondentHandler = this.onRespondentHandler.bind(this);
    this.toggleWhatsComing = this.toggleWhatsComing.bind(this);
  }

  componentDidMount() {
    document.title = "Nielsen Radio Ratings";
 }

  toggleWhatsComing() {
      window.open('/EnWhatsComing','','scrollbars=no,resizable=no,height=450,width=450');
  }

  formChange = (e) =>{
    this.setState({ ofAge:e.target.value});
  }

  validate(){
    if(!this.state.ofAge){
      this.setState({
        field_req: 'inline',
      });
      return false;
    }else{
        // clear any error State
        this.setState({field_req:'none'});
      window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));
      return true;
    }
  }

  onRespondentHandler = (e) => {
    e.preventDefault();
    if(this.validate()) {
     const apiName = 'econsent';
     const path = 'econsent/response';
     const myInit = {
       headers: {
        'headerauth1':   window.sessionStorage.getItem('token')
      },
       body: {
         'hh_param': JSON.parse(window.sessionStorage.getItem('hh_param')),
         'ofAge':this.state.ofAge,
         'action':'age_check'
       },
     };
     API.post(apiName, path, myInit)
       .then(res => {
           this.setState({hh_param:res.form_data})
           // first remove the storage before setting the response
           window.sessionStorage.removeItem('hh_param');
           window.sessionStorage.setItem('token',res.token)
           window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
             Auth.login(() => {
               if (res.form_data.nextPage === 'ELIGIBILITY_CONFIRM'){
                  this.props.navigate("/EnEligibilityConfirm",{replace:true , state:this.state});}
                // if (res.form_data.nextPage ===  'TEEN_LOCKOUT'){
                //   this.props.navigate("/EnTeenLockOut",{replace:true});}
                // if (res.form_data.nextPage ===  'SYS_ERROR'){
                //   this.props.navigate("/EnSysError",{replace:true});}
                // if(res.form_data.nextPage === 'SESSION_EXPIRED'){
                //   this.props.navigate("/SessionExpired",{replace:true});}
                if(res.form_data.nextPage === 'PERSONAL_INFO'){
                  this.props.navigate("/EnPersonalInfo",{replace:true, state:this.state});}
            });

           // if return is field empty
           if(res.form_data.nextPage === 'field_req'){
             this.setState({field_req:'inline'})
           }
       })
       .catch(error => {
         const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
         this.setState({ hh_param});
       });

    }else{
      console.log('Invalid Form');
    }
  }

  render(){
    const {nextPage} = this.state.hh_param;
    if (nextPage ===  'AFFILIATED'){
      return(<EnAffiliated/>)}
    if (nextPage ===  'HOUSEHOLD_NINE_PLUS'){
      return(<EnNinePlus/>)}
    if (nextPage ===  'PHONE_LOCKOUT'){
      return(<EnPhoneLockOut/>)}
    if (nextPage ===  'ALREADYPART'){
      return(<EnAlreadyPart/>)}
    if (nextPage ===  'TEEN_LOCKOUT'){
      return(<EnTeenLockOut/>)}
    if (nextPage ===  'SYS_ERROR'){
      return(<EnSysError/>)}
    if(nextPage === 'SESSION_EXPIRED'){
      return(<SessionExpired/>)}

  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
    <Header screen="" image="bePart"/>

  <tr>
    <td width="19" height="411" valign="top" rowSpan="2"></td>
    <td width="1" height="411" valign="top" rowSpan="2"></td>
    <td width="450" height="205" colSpan="3" valign="top">
      <p>When you enroll in the survey, we will send each person in your household
        age 12 or older a small booklet called a Nielsen Radio Ratings Diary.
        Each person will use their booklet to write down the radio stations they
        listen to during the survey week.</p>
      <p>All you have to do is tell us what you listen to &#8211; whether you
        listen a little, a lot or not at all. <a href="#" rel="noreferrer" onClick={this.toggleWhatsComing}>Here
         is what to look for in the mail.</a>      </p>
      <p align="left">In order to get started, we will need you to answer a few questions that will
      only take a couple of minutes of your time. All the information you provide will be kept in
      accordance with our <a rel="noreferrer noopener" href="http://www.nielsenradiodiary.com/privacy.htm" target="_blank">Privacy Statement</a>.</p>
      <Form onSubmit={this.onRespondentHandler} >
      <p><b><font style={{ color:"#000066"}}>*</font>Are you at least 16 years old and a member of the household?</b><br/>

        <input type="radio" id="ofAge" name="ofAge" value="Y" checked={this.state.ofAge === "Y"} onChange={this.formChange}/>
        Yes
        <input type="radio" id="ofAge" name="ofAge" value="N" checked={this.state.ofAge === "N"} onChange={this.formChange} />
        No


        	<img style={{display:this.state.field_req}}  src={Arrow2} width="36" height="18" border="0" alt="arrow"/>
        <font style={{display:this.state.field_req, color:"red"}} ><b>&nbsp;&nbsp;An answer is required.</b></font>

	       </p>
      <p align="center"> <br/>
        <input type="submit" name="Continue_to_2" value="Continue"/>
      </p>
      </Form>
    </td>
  </tr>
  <tr>
    <td  width="433" colSpan="3">
      <EnLegalFooter/>
    </td>
  </tr>
  </tbody>
</Table>
    </div>
    );
}
}


export default WithRouter(EnEligibility);
