import React from 'react';

import Transparent from "../images/transparent.gif";
import Logo from "../images/nielsenlogo.gif";
import En_thankYou from "../images/title_thank_you.gif";
import En_bepart from "../images/title_bepart.gif";
import { Link } from "react-router-dom";
import En_bepart_title from "../images/bepart_title_eng.gif";

import ThankYou from "../images/title_thankyou_sp.gif";
class Header extends React.Component {

  state = {
    screen : false,
    image:"",
    image_height:"68",
    image_width:"331"
  }

  constructor(props){
    super(props);
    if (this.props.screen === 'home'){
        this.state.screen = true;
    }

    if(this.props.image === 'bePartNielsenTitle'){
      this.state.image=En_bepart_title;
      this.state.image_width="460";
      this.state.image_height="110";

    }
    else if(this.props.image === 'thankyou'){
      this.state.image=En_thankYou;
      this.state.image_width="331";
      this.state.image_height="68";
    }
    else if(this.props.image === 'bePart'){
      this.state.image=En_bepart;
      this.state.image_width="331";
      this.state.image_height="68";
    }
    else if(this.props.image === 'thankyouSpEn'){
      this.state.image=ThankYou;
      this.state.image_width="";
      this.state.image_height="";
    }

  }

  render() {
    return (

      <tr>
        <td colSpan="3" height="17" valign="top" align="left">
          <table width="100%" border="0" cellPadding="0" cellSpacing="0" align="left">
          <tbody>
            <tr align="left">
              <td valign="top"><img alt="" src={Transparent} width="331" height="31" align="right"/>
              {this.state.screen && (<Link to="/SpHome"  replace id="languageToggle">Espa&ntilde;ol</Link>)}
              </td>
            </tr>
              <tr align="right">
                <td valign="top"><img src={Transparent} alt="logo" width="270" height="54" align="left"/><img alt="logo1" src={Logo} width="160" height="54" align="right" className="arbLogo"/></td>
              </tr>
              <tr align="left">
                <td valign="top"><img alt="bepart" src={this.state.image} width={this.state.image_width} height={this.state.image_height}/></td>
              </tr>
              </tbody>
            </table>

          </td>
        </tr>

    );
  }
}

export default Header;
