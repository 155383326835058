import React from 'react';
import {Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';
import { WithRouter } from '../components/WithRouter';

class EnThankYou extends React.Component {

  state={
    survey_date:''
  }

  constructor(props){
    super(props);
    this.state.survey_date = props.location.state;
  }
  componentDidMount() {
      document.title = 'Thank you';
      window.addEventListener("popstate", () => {
       this.props.navigate(1);
     });
 }

  toggleWhatsComing= () => {
    window.open('/EnWhatsComing','','scrollbars=no,resizable=no,height=450,width=450');
  }

render(){
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
<tbody>
        <Header image="thankyou" screen=""/>

  <tr>
    <td width="20"  valign="top"></td>
    <td width="413" valign="top" align="left">
        <p>&nbsp;</p>
        <p>Thanks for taking the time to enroll! </p>
                <p > <a href="#" onClick={this.toggleWhatsComing}>Watch for your diary
        package in the mail</a>. </p>
        <p>Your household will receive a diary package a few days before the start
         of the survey.  It will include a small cash gift as a token of our appreciation, enough
         for a cup of coffee or a doughnut.
         The first day of the survey is Thursday {this.state.survey_date}.</p>

         <p>Because each home is so important, we'll call you right before the survey
          begins to make sure your booklets have arrived and to answer any questions you
          might have.</p>
       <p>When the survey week is over, just send the booklets back to us.  Postage and a
          return address will already be on each booklet.</p>
       <p>If you have any questions, contact us at 1-800-204-3174.</p>

        </td>
        </tr>

                <EnLegalFooter/>
                </tbody>
        </Table>

    </div>
    )
  }
}



export default WithRouter(EnThankYou);
