import React from 'react';
import { EnHome, SpHome, EnWhatsComing,EnHowSelected,
	SpHowSelected,SpWhatsComing,EnEligibility,SpEligibility,
	EnEligibilityConfirm,SpEligibilityConfirm,EnPersonalInfo,SpPersonalInfo,EnWork,
	SpWork,EnPhConfirm,SpPhConfirm,EnAgeGender,SpAgeGender, EnRace, EnOrigin,
	SpRace, SpOrigin,SpThankYou,EnThankYou,EnInProgress, SpInProgress}  from './pages';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './css/default.css';
import Amplify from "aws-amplify";
import config from './config/amplify_config';

import { ProtectedRoute } from "./components/ProtectedRoute";

import awsExports from './aws-exports';

Amplify.configure(config);

class App extends React.Component{

	render(){
    return (
			<BrowserRouter>
			<Routes>

        	<Route exact path="/" element={<EnHome />}/>
					<Route path="/SpHome" element={<SpHome />}/>
					<Route path="/*" element={<EnHome />}/>
					<Route path="/EnWhatsComing" element={<EnWhatsComing/>}/>
					<Route path="/SpWhatsComing" element={<SpWhatsComing/>}/>
					<Route path="/EnHowSelected" element={<EnHowSelected/>}/>
					<Route path="/SpHowSelected" element={<SpHowSelected/>}/>
					<Route path="/EnWork" element={<EnWork/>}/>
					<Route path="/SpWork" element={<SpWork/>}/>
					<Route path="/EnOrigin" element={<EnOrigin/>}/>
					<Route path="/SpOrigin" element={<SpOrigin/>}/>


		<Route exact path="/EnEligibility" element={
			<ProtectedRoute>
				<EnEligibility />
			</ProtectedRoute>
	} />
	<Route exact path="/SpEligibility" element={
		<ProtectedRoute>
			<SpEligibility />
			</ProtectedRoute>
} />
<Route exact path="/EnEligibilityConfirm" element={
	<ProtectedRoute>
		<EnEligibilityConfirm />
	</ProtectedRoute>
} />
<Route exact path="/SpEligibilityConfirm" element={
<ProtectedRoute>
	<SpEligibilityConfirm />
	</ProtectedRoute>
} />
<Route exact path="/EnPersonalInfo" element={
	<ProtectedRoute>
		<EnPersonalInfo />
		</ProtectedRoute>
} />
<Route exact path="/SpPersonalInfo" element={
<ProtectedRoute>
	<SpPersonalInfo />
	</ProtectedRoute>
} />
<Route exact path="/EnPhConfirm" element={
<ProtectedRoute>
	<EnPhConfirm />
</ProtectedRoute>
} />
<Route exact path="/SpPhConfirm" element={
<ProtectedRoute>
	<SpPhConfirm />
	</ProtectedRoute>
} />
<Route exact path="/EnAgeGender" element={
<ProtectedRoute>
		<EnAgeGender />
		</ProtectedRoute>
} />
<Route exact path="/SpAgeGender" element={
<ProtectedRoute>
		<SpAgeGender />
		</ProtectedRoute>
} />
<Route exact path="/EnRace" element={
<ProtectedRoute>
		<EnRace />
		</ProtectedRoute>
} />
<Route exact path="/SpRace" element={
	<ProtectedRoute>
		<SpRace />
		</ProtectedRoute>
} />
<Route exact path="/EnThankYou" element={
	<ProtectedRoute>
		<EnThankYou />
		</ProtectedRoute>
} />
<Route exact path="/SpThankYou" element={
	<ProtectedRoute>
		<SpThankYou />
		</ProtectedRoute>
} />
<Route exact path="/EnInProgress" element={
	<ProtectedRoute>
		<EnInProgress />
		</ProtectedRoute>
} />
<Route exact path="/SpInProgress" element={
	<ProtectedRoute>
		<SpInProgress />
		</ProtectedRoute>
} />

				</Routes>
      </BrowserRouter>

    );
	  }
  }


export default App;
