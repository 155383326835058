import React , { useEffect } from 'react';
import { Link , useNavigate } from "react-router-dom";
import {Table} from 'react-bootstrap';
import Header from '../components/Header';

const SessionExpired = (props) => {
  const navigate = useNavigate();
    useEffect(() => {
      document.title = 'Nielsen Radio Ratings';
      window.addEventListener("popstate", () => {
       navigate(1);
     });
    });
  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <Header image="thankyouSpEn" screen=""/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
    <p>&nbsp;</p>
              <p>Your session has expired due to an invalid action or 20 minutes of inactivity.<br/>
    <center><Link to={{pathname: "/"}} id="languageToggle">Return to Homepage</Link></center></p>
    <p>&nbsp;</p>
    <p>Su tiempo ha expirado debido a una acci&oacute;n inv&aacute;lida o m&aacute;s de 20 minutos sin actividad.<br/>
    <Link to={{pathname: "/SpHome"}} id="languageToggle">Regresar a la P&aacute;gina Principal</Link></p>
    <p>&nbsp;</p>
    </td>
</tr>

</Table>
</div>
    );
};


export default SessionExpired;
