import React from 'react';
import { Table } from 'react-bootstrap';
import '../css/default.css';
import EnCopyRights from '../components/EnCopyRights';

class EnWork extends React.Component {

  constructor(props){
    super(props);
     this.closeTab = this.closeTab.bind(this);
  }
  closeTab() {
      window.opener = null;
      window.open("", "_self");
      window.close();
    }

  componentDidMount() {
    document.title = "Media Affiliation";
  }

  render(){
  return (
    <div align="center">
<Table cellSpacing="0" cellPadding="0" border="0" style={{marginLeft:"5px", marginRight: "20px"}}>
<tbody>
  <tr>
    <td height="40" valign="top" align="left" width="20" rowSpan="2">

    </td>
    <td height="12" valign="middle" align="left">&nbsp; </td>
    <td height="24" valign="top" align="left" width="20" rowSpan="2">&nbsp;</td>
  </tr>
  <tr>
    <td height="20" valign="middle" align="left"><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
      to close window.</a></td>
  </tr>
  <tr>
    <td valign="top" height="250"></td>
    <td width="410" height="250" valign="top" align="left">
      <p>We would like
        to know if anyone in your household is a volunteer, intern, full-time
        employee, or part-time employee <br/>
        for a:</p>
      <ul>
        <li>Television or radio station or network</li>
        <li>Cable television company</li>
        <li>Business or organization having a controlling interest in or that
          is controlled by a broadcasting entity, or</li>
        <li>A media measurement service</li>
      </ul>
      <p><br></br></p>
        <p style={{marginBottom:'0px'}}><a style={{fontSize:'x-small'}} href="#" onClick={this.closeTab}>Click
          to close window.</a></p>
        </td>
        <td height="250" valign="top" align="left">&nbsp;</td>
        </tr>
        <tr>
        <td valign="top" height="20"></td>
        <td width="408" valign="top">
        <EnCopyRights/>
        </td>
        <td valign="top">&nbsp;</td>
        </tr>
        </tbody>
        </Table>
        </div>
  );
}
}

export default EnWork;
