import React from 'react';
import {Table, Form} from 'react-bootstrap';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';
import Arrow2 from "../images/arrow2.jpg";
import { WithRouter } from "../components/WithRouter";
import { API } from 'aws-amplify';
import Auth from "../model/Auth";

import {SpSysError, SpTeenLockOut, SessionExpired, SpAffiliated,SpAlreadyPart, SpNinePlus, SpPhoneLockOut} from './';

class SpEligibility  extends React.Component  {

  state = {
      hh_param:{
        sample_id: '',
        languageUsed:'SP',
        nextPage: '',
        original_token:'',
        frameCode:''
      },
     field_req:'none',
     ofAge:''
   }

  constructor(props){
    super(props);
    if(props.location.state){
      this.state = props.location.state;
    }
    this.state.hh_param = JSON.parse(window.sessionStorage.getItem('hh_param'));
    this.onRespondentHandler = this.onRespondentHandler.bind(this);
    this.toggleWhatsComing = this.toggleWhatsComing.bind(this);
  }


  componentDidMount() {
    document.title = "Nielsen Radio Ratings";
    window.addEventListener("popstate", () => {
     this.props.navigate(1);
   });
 }

  toggleWhatsComing() {
      window.open('/SpWhatsComing','','scrollbars=no,resizable=no,height=450,width=450');
  }

  formChange = (e) =>{
    this.setState({ ofAge:e.target.value});
  }

  validate(){
    if(!this.state.ofAge){
      this.setState({
        field_req: 'inline',
      });
      return false;
    }else{
        // clear any error State
        this.setState({field_req:'none'});
      window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
      return true;
    }
  }

  onRespondentHandler = (e) => {
    e.preventDefault();
    if(this.validate()) {
     const apiName = 'econsent';
     const path = 'econsent/response';
     const myInit = {
       headers: {
        'headerauth1':   window.sessionStorage.getItem('token')
      },
       body: {
         'hh_param': JSON.parse(window.sessionStorage.getItem('hh_param')),
         'ofAge':this.state.ofAge,
         'action':'age_check'
       },
     };
     API.post(apiName, path, myInit)
       .then(res => {
           this.setState({hh_param:res.form_data})
           // first remove the storage before setting the response
           window.sessionStorage.removeItem('hh_param');
           window.sessionStorage.setItem('token',res.token)
          window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
             Auth.login(() => {
               if (res.form_data.nextPage === 'ELIGIBILITY_CONFIRM'){
                  this.props.navigate("/SpEligibilityConfirm",{replace:true, state:this.state});}
                // if (res.form_data.nextPage ===  'TEEN_LOCKOUT'){
                //   this.props.navigate("/SpTeenLockOut",{replace:true});}
                // if (res.form_data.nextPage ===  'SYS_ERROR'){
                //   this.props.navigate("/SpSysError",{replace:true});}
                // if(res.form_data.nextPage === 'SESSION_EXPIRED'){
                //   this.props.navigate("/SessionExpired",{replace:true});}
                if(res.form_data.nextPage === 'PERSONAL_INFO'){
                  this.props.navigate("/SpPersonalInfo",{replace:true,state:this.state});}
                });

           // if return is field empty
           if(res.form_data.nextPage === 'field_req'){
             this.setState({field_req:'inline'})
           }
       })
       .catch(error => {
         const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
         this.setState({ hh_param});
       });

    }else{
      console.log('Invalid Form');
    }
  }


  render(){
    const {nextPage} = this.state.hh_param;
    if (nextPage ===  'TEEN_LOCKOUT'){
      return(<SpTeenLockOut/>)}
    if (nextPage ===  'SYS_ERROR'){
      return(<SpSysError/>)}
    if(nextPage === 'SESSION_EXPIRED'){
      return(<SessionExpired/>)}
    if (nextPage ===  'AFFILIATED'){
      return(<SpAffiliated/>)}
    if (nextPage ===  'HOUSEHOLD_NINE_PLUS'){
      return(<SpNinePlus/>)}
    if (nextPage ===  'PHONE_LOCKOUT'){
      return(<SpPhoneLockOut/>)}
    if (nextPage ===  'ALREADYPART'){
      return(<SpAlreadyPart/>)}

  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
    <SpHeader screen="" image="bePart"/>

  <tr>
    <td width="19" height="411" valign="top" rowSpan="2"></td>
    <td width="1" height="411" valign="top" rowSpan="2"></td>
    <td width="450" height="205" colSpan="3" valign="top">
      <p>Cuando se inscriba en la encuesta, le enviaremos a cada persona de su
        hogar que tenga 12 a&ntilde;os o m&aacute;s un cuadernito llamado Diario de Ratings
        de Radio de Nielsen. Cada persona usar&aacute; su cuadernito para escribir las estaciones
        de radio que escuche durante la semana de la encuesta.</p>
      <p>Todo lo que debe hacer es decirnos lo que escucha (si lo escucha poco, mucho o
        no lo escucha).  <a href="#" rel="noreferrer" onClick={this.toggleWhatsComing}>Esto es lo que debe esperar que le llegue por correo.</a>      </p>
      <p align="left">Para poder empezar, necesitamos que responda algunas preguntas.
        S&oacute;lo le tomar&aacute; unos minutos y todas sus respuestas ser&aacute;n
        confidenciales. No venderemos ni divulgaremos su nombre ni su direcci&oacute;n,
        ni trataremos de venderle nada. <a href="http://www.nielsenradiodiary.com/privacy_es.htm" rel="noreferrer noopener" target="_blank">Declaraci&oacute;n de Privacidad</a>.</p>
<Form onSubmit={this.onRespondentHandler} >
      <p><b><font style={{ color:"#000066"}}>*</font> &iquest;Tiene usted por lo menos 16 a&ntilde;os de edad y es un miembro del hogar?</b><br/>
      <input type="radio" id="ofAge" name="ofAge" value="Y" checked={this.state.ofAge === "Y"} onChange={this.formChange}/>
      S&iacute;
      <input type="radio" id="ofAge" name="ofAge" value="N" checked={this.state.ofAge === "N"} onChange={this.formChange} />
      No


        	<img style={{display:this.state.field_req}}  src={Arrow2} width="36" height="18" border="0" alt="arrow"/>
        <font style={{display:this.state.field_req, color:"red"}} ><b>&nbsp;&nbsp;Se requ&#237;ere una respuesta.</b></font>

	       </p>
      <p align="center"> <br/>
        <input type="submit" name="Continue_to_2" value="Continuar"/>
      </p>
      </Form>
    </td>
  </tr>
  <tr>
    <td  width="433" colSpan="3">
      <SpLegalFooter/>
    </td>
  </tr>
  </tbody>
</Table>
    </div>
    );
}
}


export default WithRouter(SpEligibility);
