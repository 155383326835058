import React  from 'react';
import { Form, Table } from 'react-bootstrap';
import { API } from 'aws-amplify';

import EnLegalFooter from '../components/EnLegalFooter';
import Header from '../components/Header';
import { WithRouter } from "../components/WithRouter";
import Auth from "../model/Auth";

import {EnMisType, EnInProgress,EnAffiliated, EnAlreadyPart, EnDeadlinePast,EnNinePlus,EnNotCellOnly,EnPhoneLockOut,EnSysError,EnTeenLockOut,EnThreeTrys,SessionExpired} from './';

import Buttonsstacked2pink from "../images/buttonsstacked2pink.gif";
import Buttonsname from "../images/buttonsname.gif";
import Buttonsmail from "../images/buttonsmail.gif";
import Arrow2 from "../images/arrow2.jpg";


class EnHome extends React.Component {

  state = {
      hh_param:{
        sample_id: '',
        languageUsed:'EN',
        nextPage: '',
        iBadLoginCount:0,
        frameCode : '',
        original_token:''
      },
     mapImage:Buttonsstacked2pink,
     sampId_req:'none'
   }

   constructor(props){
     super(props);
     let badCount = 0;
     if( JSON.parse(window.sessionStorage.getItem('hh_param')) !== null){
       badCount = JSON.parse(window.sessionStorage.getItem('hh_param'))['iBadLoginCount'];
     }
     this.state.hh_param = {'sample_id':'','languageUsed':'EN','nextPage':'','iBadLoginCount':badCount,'original_token':'','frameCode':''};
     window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));
     this.formChange = this.formChange.bind(this);
     this.onLoginHandler = this.onLoginHandler.bind(this);
     this.toggleHowSelected = this.toggleHowSelected.bind(this);
     this.toggleWhatsComing = this.toggleWhatsComing.bind(this);
   }

   componentDidMount() {
     document.title = "Welcome to Nielsen";
     document.getElementById('sample_id').focus();
     window.addEventListener("popstate", () => {
      this.props.navigate(1);
    });
  }

  toggleHowSelected() {
      window.open('/EnHowSelected','','scrollbars=no,resizable=no,height=300,width=450');
  }

  toggleWhatsComing() {
      window.open('/EnWhatsComing','','scrollbars=no,resizable=no,height=450,width=450');
  }


    formChange = (e) =>{
      const hh_param = { ...this.state.hh_param,[e.target.id]: e.target.value.trim() };
      this.setState({ hh_param});
    }

    validate(){
      if(!this.state.hh_param.sample_id){
        this.setState({
          sampId_req: 'inline',
        });
        return false;
      }else{
        window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
        return true;
      }
    }

    onLoginHandler = (e) => {
      e.preventDefault();
      if(this.validate()) {
       const apiName = 'econsent';
       const path = 'econsent/login';
       const myInit = {
         headers: {
          'headerauth1':   window.sessionStorage.getItem('token') || ''
        },
         queryStringParameters: {
           'form_data': window.sessionStorage.getItem('hh_param'),
         },
       };
       API.get(apiName, path, myInit)
         .then(res => {
             this.setState({hh_param:res.form_data});
             window.sessionStorage.setItem('token',res.token);
            window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param));
               Auth.login(() => {
              //    if (res.form_data.nextPage === 'MISTYPE'){
              //       this.props.navigate("/EnMisType", {replace:true});}
              //     if (res.form_data.nextPage ===  'THREE_TRYS'){
              //       this.props.navigate("/EnThreeTrys", {replace:true});}
              //     if (res.form_data.nextPage ===  'DEADLINE_PAST'){
              //       this.props.navigate("/EnDeadlinePast", {replace:true});}
              //     if (res.form_data.nextPage ===  'ALREADYPART'){
              //       this.props.navigate("/EnAlreadyPart", {replace:true});}
              //     if (res.form_data.nextPage ===  'AFFILIATED'){
              //       this.props.navigate("/EnAffiliated", {replace:true});}
              //     if (res.form_data.nextPage ===  'HOUSEHOLD_NINE_PLUS'){
              //       this.props.navigate("/EnNinePlus", {replace:true});}
              //     if (res.form_data.nextPage ===  'PHONE_LOCKOUT'){
              //       this.props.navigate("/EnPhoneLockOut", {replace:true});}
              //     if (res.form_data.nextPage ===  'TEEN_LOCKOUT'){
              //       this.props.navigate("/EnTeenLockOut", {replace:true});}
              //     if (res.form_data.nextPage ===  'NOT_CELL_ONLY'){
              //       this.props.navigate("/EnNotCellOnly", {replace:true});}
              //     if (res.form_data.nextPage ===  'SYS_ERROR'){
              //       this.props.navigate("/EnSysError", {replace:true});}
                  if (res.form_data.nextPage ===  'ELIGIBILITY'){
                      this.props.navigate("/EnEligibility", {replace:true});}
              //     if(res.form_data.nextPage === 'SESSION_EXPIRED'){
              //         this.props.navigate("/SessionExpired", {replace:true});}
              });

             // if return is sampid empty
             if(res.form_data.nextPage === 'sampId_req'){
               this.setState({sampId_req:'inline'})
             }
         })
         .catch(error => {
           const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
           this.setState({ hh_param});
         });

      }else{
        console.log('Invalid Form');
      }
    }


render(){
  const { hh_param,mapImage,sampId_req} = this.state;
   if (hh_param.nextPage === 'MISTYPE'){
     return(<EnMisType/>)}
   if (hh_param.nextPage ===  'THREE_TRYS'){
     return(<EnThreeTrys/>)}
   if (hh_param.nextPage ===  'DEADLINE_PAST'){
     return(<EnDeadlinePast/>)}
   if (hh_param.nextPage ===  'ALREADYPART'){
     return(<EnAlreadyPart/>)}
   if (hh_param.nextPage ===  'AFFILIATED'){
     return(<EnAffiliated/>)}
   if (hh_param.nextPage ===  'HOUSEHOLD_NINE_PLUS'){
     return(<EnNinePlus/>)}
   if (hh_param.nextPage ===  'PHONE_LOCKOUT'){
     return(<EnPhoneLockOut/>)}
   if (hh_param.nextPage ===  'TEEN_LOCKOUT'){
     return(<EnTeenLockOut/>)}
   if (hh_param.nextPage ===  'NOT_CELL_ONLY'){
     return(<EnNotCellOnly/>)}
   if (hh_param.nextPage ===  'SYS_ERROR'){
     return(<EnSysError/>)}
   if (hh_param.nextPage ===  'IN_PROGRESS'){
     return(<EnInProgress sample_id = {hh_param.sample_id}/>)}
   if(hh_param.nextPage === 'SESSION_EXPIRED'){
       return(<SessionExpired/>)
    }
    return (
      <div>
      <Table cellSpacing="0" cellPadding="0" border="0">
      <tbody>

        <Header screen="home" image="bePartNielsenTitle"/>

        <tr><td align="left" >&nbsp;</td></tr>
  <tr>
    <td width="19" height="411" valign="top" rowSpan="2"></td>
    <td width="1" height="411" valign="top" rowSpan="2"></td>
    <td width="450" height="205" colSpan="3" valign="top">
        <p>
          <img className="mapImage" src={mapImage} alt="map" width="102" height="92" align="right" useMap="#Map" border="0" name="Image1"/>
          <map name="Map">
            <area shape="rect" coords="-2,0,96,33" alt="" onClick={this.toggleHowSelected} onMouseOut={() => {this.setState({mapImage: Buttonsstacked2pink})}} onMouseOver={() => {this.setState({mapImage: Buttonsname})}}/>
            <area shape="rect" coords="1,40,95,73" alt="" onClick={this.toggleWhatsComing} onMouseOut={() => {this.setState({mapImage: Buttonsstacked2pink})}} onMouseOver={() => {this.setState({mapImage: Buttonsmail})}}/>
          </map>
          For more than 50 years, Nielsen has been a leading provider of media and marketing information, including the Nielsen Radio and TV ratings. We measure radio listening across the country. We produce the radio ratings reports for cities nationwide. Stations use Nielsen research to make important decisions about what you hear on the radio. Each week we choose a few households in your area to be in the radio ratings. It is simple, takes only a few minutes each day, and we will send you a small cash gift as a token of our appreciation.
          </p>
          <p>To get started, enter the 10-digit serial number printed above your name and address on the postcard or note card envelope, or use the 10-digit number referenced in your letter.</p>
          <Form onSubmit={this.onLoginHandler} autoComplete="off">
            <p>Serial number:{' '}
              <input type="text" pattern="[a-zA-Z0-9]*" onChange={this.formChange} defaultValue={''} name="sample_id" id="sample_id" maxLength="10" size="10"/>
              <img style={{display:sampId_req}} alt="arrow" src={Arrow2} width="36" height="18" border="0"alt=""/>
              <font style={{display:sampId_req, color:"red"}} ><b>Please enter serial number.</b></font>
            </p>
            <p align="center">
              <input type="submit" name="Submit" value="Submit"/>
            </p>
          </Form>
          <p align="left">We hope we can count on you to participate. If you have any unanswered questions, call us toll-free at 1-800-204-3174 or <a href="http://www.nielsenradiodiary.com/" rel="noreferrer noopener" target="_blank">click here</a> to visit our website for more information.
        </p>

    </td>
  </tr>
  <tr>
    <td colSpan="3">
        <EnLegalFooter/>
    </td>
  </tr>
  </tbody>
  </Table>


        </div>
    );
  }
}

export default WithRouter(EnHome);
