import React from 'react';
import {Form, Table} from 'react-bootstrap';
import EnLegalFooter from "../components/EnLegalFooter";
import Header from '../components/Header';
import Arrow2 from "../images/arrow2.jpg";
import { WithRouter } from "../components/WithRouter";
import { API } from 'aws-amplify';
import Auth from "../model/Auth";
import {EnSysError, EnPhoneLockOut, EnAffiliated, SessionExpired, EnNinePlus,EnAlreadyPart } from './';

class EnPersonalInfo  extends React.Component  {

  state = {
      hh_param:{
        sample_id: '',
        languageUsed:'EN',
        nextPage: '',
        iBadLoginCount:0,
        frameCode:'',
        original_token:''
      },
      pinfo:{
        title:'',
        title_text:'',
        firstName:'',
        lastName:'',
        addrStreet1:'',
        addrApt:'',
        addrCity:'',
        addrState:'',
        addrZip:'',
        phoneAreaCode:'',
        phonePrefix:'',
        phoneSufix:'',
        networkAffiliate:'',
        householdNum:''
      },
     errorAddress:'none',
     errorState:'none',
     errorCity:'none',
     errorZip:'none',
     errorInvalidZip:'none',
     errorInvalidPhone:'none',
     errorPhone:'none',
     errorPhoneNmbrMismatch:'none',
     errorAffiliation:'none',
     pPHConfirm:'none',
     errorHouseholdNmbr:'none',
     error:'none'
   }

  constructor(props){
    super(props);
    this.state.hh_param = JSON.parse(window.sessionStorage.getItem('hh_param'));
    this.state.pinfo = { 'title':'','title_text':'','firstName':'','lastName':'','addrStreet1':'','addrApt':'','addrCity':'','addrState':'','addrZip':'','phoneAreaCode':'','phonePrefix':'','phoneSufix':'','networkAffiliate':'','householdNum':''};
    if(props.location.state.pinfo){
      this.state = props.location.state;
      if (this.state.pPHConfirm === 'inline'){
        this.state.errorHouseholdNmbr = 'inline';
      }
    }else{
      this.state.back_data = props.location.state;
    }
    this.onRespondentHandler = this.onRespondentHandler.bind(this);
    this.toggleHowSelected = this.toggleHowSelected.bind(this);
    this.toggleWork = this.toggleWork.bind(this);
  }

  componentDidMount() {
    document.title = "Nielsen Radio Ratings";
    window.addEventListener("popstate", () => {
     this.props.navigate(1);
   });
 }

 toggleHowSelected() {
     const a = window.open('/EnHowSelected','','scrollbars=no,resizable=no,height=350,width=450');
     a.document.title = 'How was my household selected?';
 }
 toggleWork() {
     window.open('/EnWork','','scrollbars=no,resizable=no,height=350,width=450');
 }


 handleFieldChange =(e)=>{
   if (e.target.id === "title" && e.target.value !== "Other") {
     var pinfo1 = { ...this.state.pinfo,title_text: '' };
     this.setState({ pinfo:pinfo1},()=>{
       document.getElementById('title_text').value='';
     });
   }
   if (e.target.id === "title_text" ) {
     var pinfo2 = { ...this.state.pinfo,title: 'Other' };
     this.setState({ pinfo:pinfo2});
   }
 }

 formChange = (e) =>{
    var pinfo = { ...this.state.pinfo,[e.target.id]: e.target.value };
    this.setState({ pinfo:pinfo});
 }

 validPhone =(option)=>{
   switch (option){
     case "one":
     if(this.state.pinfo.phoneAreaCode.trim().length===0 || this.state.pinfo.phoneAreaCode===""
        || this.state.pinfo.phonePrefix==="" || this.state.pinfo.phonePrefix.trim().length===0
        || this.state.pinfo.phoneSufix==="" || this.state.pinfo.phoneSufix.trim().length === 0){
        return false;
      }
      else{
        return true;
      }
     case "two":
     if(this.state.pinfo.phoneAreaCode.trim().length!==3 || this.state.pinfo.phoneAreaCode===""
        || this.state.pinfo.phonePrefix==="" || this.state.pinfo.phonePrefix.trim().length!==3
        || this.state.pinfo.phoneSufix==="" || this.state.pinfo.phoneSufix.trim().length !== 4){
        return false;
      }else{
        let reg1 = RegExp(/^[0-9][0-9][0-9]$/i)
        let reg2 = RegExp(/^[0-9][0-9][0-9][0-9]$/i)
        return reg1.test(this.state.pinfo.phoneAreaCode) &&
               reg1.test(this.state.pinfo.phonePrefix) &&
               reg2.test(this.state.pinfo.phoneSufix);
      }

     default:
      return true;
   }

 }

 validZip = () =>{
   if(this.state.pinfo.addrZip === '' | this.state.pinfo.addrZip.trim().length!==5)
		{
			return false;
		}
		else
		{
      let reg = RegExp(/^[0-9]{5}$/i);
			return reg.test(this.state.pinfo.addrZip);
		}
 }

  validate = () =>{
    var valid = true;
    if(!this.state.pinfo.addrStreet1){
      this.setState({
        errorAddress: 'inline',
      });
      valid = false;
    }else{
      this.setState({
        errorAddress: 'none',
      });
    }
    if(!this.state.pinfo.addrCity){
      this.setState({
        errorCity: 'inline',
      });
      valid = false;
    }else{
      this.setState({
        errorCity: 'none',
      });
    }
    if(!this.state.pinfo.addrState){
      this.setState({
        errorState: 'inline',
      });
      valid = false;
    }else{
      this.setState({
        errorState: 'none',
      });
    }
    if(!this.state.pinfo.addrZip){
      this.setState({
        errorZip: 'inline',
      });
      valid = false;
    }else if(!this.validZip()){
      this.setState({
        errorZip: 'inline',errorInvalidZip: 'inline',
      });
      valid = false;
    }else{
      this.setState({
        errorZip: 'none',errorInvalidZip: 'none',
      });
    }
    if(!this.validPhone("one")){
      this.setState({
        errorPhone: 'inline',
      });
      valid = false;
    }else if(!this.validPhone("two")){
      this.setState({
        errorPhone: 'inline',errorInvalidPhone: 'inline',
      });
      valid = false;
    }else{
      this.setState({
        errorPhone: 'none',errorInvalidPhone: 'none',
      });
    }
    if(!this.state.pinfo.networkAffiliate){
      this.setState({
        errorAffiliation: 'inline',
      });
      valid = false;
    }else{
      this.setState({
        errorAffiliation: 'none',
      });
    }
    if(!this.state.pinfo.householdNum){
      this.setState({
        errorHouseholdNmbr: 'inline',
      });
      valid = false;
    }else{
      this.setState({
        errorHouseholdNmbr: 'none',
      });
    }
    if (!valid){
      this.setState({
        error:'',
      });
      return false;
    }else{
      // window.sessionStorage.setItem('pinfo',JSON.stringify(this.state.pinfo))
      this.setState({
        error:'none',errorPhoneNmbrMismatch:'none', pPHConfirm:'none',errorHouseholdNmbr : 'none'
      });
      return true;
    }
  }

   autoTab=(e,destination)=>{
     if ((e.target.id === 'phoneAreaCode' || e.target.id === 'phonePrefix' )&& e.target.value.length===e.target.maxLength)
     {
       if (destination === 'phonePrefix'){
         document.getElementById("phonePrefix").focus();
         document.getElementById("phonePrefix").select();
       }
       else{
         document.getElementById("phoneSufix").focus();
         document.getElementById("phoneSufix").select();
       }
     }
   }
   back = (e) =>{
       this.props.navigate("/EnEligibility",{replace:true , state:this.state.back_data});
   }

  onRespondentHandler = (e) => {
    e.preventDefault();
    if(this.validate()) {
     const apiName = 'econsent';
     const path = 'econsent/response';
     const myInit = {
       headers: {
        'headerauth1':   window.sessionStorage.getItem('token')
      },
       body: {
         'hh_param': JSON.parse(window.sessionStorage.getItem('hh_param')),
         'pinfo': this.state.pinfo,
         'action':'pinfo'
       },
     };
     API.post(apiName, path, myInit)
       .then(res => {
           this.setState({hh_param:res.form_data})
           window.sessionStorage.removeItem('hh_param');
           window.sessionStorage.setItem('token',res.token)
          window.sessionStorage.setItem('hh_param',JSON.stringify(this.state.hh_param))
          // if return phone number mismatch
          if(res.form_data.nextPage === 'errorPhoneNmbrMismatch'){
            this.setState({errorPhoneNmbrMismatch:'inline',errorPhone:'inline'})
          }else {
             Auth.login(() => {
                if (res.form_data.nextPage ===  'PPH_CONFIRM'){
                  this.props.navigate("/EnPhConfirm", {replace:true, state:this.state});}
                // if (res.form_data.nextPage ===  'SYS_ERROR'){
                //   this.props.navigate("/EnSysError", {replace:true});}
                // if(res.form_data.nextPage === 'SESSION_EXPIRED'){
                //   this.props.navigate("/SessionExpired",{replace:true});}
                // if(res.form_data.nextPage === 'PHONE_LOCKOUT'){
                //   this.props.navigate("/EnPhoneLockOut",{replace:true});}
                // if (res.form_data.nextPage ===  'AFFILIATED'){
                //   this.props.navigate("/EnAffiliated",{replace:true});}
                // if (res.form_data.nextPage ===  'HOUSEHOLD_NINE_PLUS'){
                //   this.props.navigate("/EnNinePlus",{replace:true});}
            });
          }

       })
       .catch(error => {
         const hh_param = { ...this.state.hh_param,nextPage: 'SYS_ERROR' };
         this.setState({ hh_param});
       });

    }else{
      console.log('Invalid Form');
    }

  }



  render(){
    const {pinfo} = this.state;
    const {nextPage} = this.state.hh_param;
    if (nextPage ===  'SYS_ERROR'){
      return(<EnSysError/>)}
    if(nextPage === 'SESSION_EXPIRED'){
      return(<SessionExpired/>)}
    if(nextPage === 'PHONE_LOCKOUT'){
      return(<EnPhoneLockOut/>)}
    if (nextPage ===  'AFFILIATED'){
      return(<EnAffiliated/>)}
    if (nextPage ===  'HOUSEHOLD_NINE_PLUS'){
      return(<EnNinePlus/>)}
    if (nextPage ===  'ALREADYPART'){
      return(<EnAlreadyPart/>)}

  return (
    <div>
    <Form name="myform" onSubmit={this.onRespondentHandler} autoComplete="off">
    <Table cellSpacing="0" cellPadding="0" border="0">
    <tbody>
    <Header screen="" image="bePart"/>

    <tr>
    <td width="1"  valign="top" rowSpan="2">&nbsp;</td>
    <td colSpan="1" valign="top">
      <br></br>
  	  <Table width="400" border="0">
      <tbody>
  	   <tr>
  	    <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
  	     <td width="1">&nbsp;</td>
  	     <td><p> <b>Please fill in your name and address so we can send you the survey materials.</b></p></td>
  	   </tr>

  	   <tr>
  	    <td colSpan="2"></td>
  	    <td>
  	     <Table width="450" border="0" cellSpacing="0" cellPadding="0">
          <tbody>
            <tr valign="top">
              <td align="right" height="25"><div align="right">Title:&nbsp;</div></td>
              <td>&nbsp;&nbsp;&nbsp;</td>
              <td><input type="radio" id="title" name="title" value="Mr." checked={pinfo.title === "Mr."} onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Mr.{' '}
              <input type="radio" id="title" name="title" value="Mrs." checked={pinfo.title === "Mrs."}   onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Mrs.{' '}
              <input type="radio" id="title" name="title" value="Ms." checked={pinfo.title === "Ms."} onChange={this.formChange} onClick={this.handleFieldChange}/>
              {' '}    Ms.{' '}
              <input type="radio" id="title" name="title" value="Miss" checked={pinfo.title === "Miss"}  onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Miss{' '}
              <br/>
              <input type="radio" id="title" name="title" checked={pinfo.title === "Other"} value="Other"  onChange={this.formChange} onClick={this.handleFieldChange} />
                {' '}  Other:{' '}
              <input type="text" name="title_text" id="title_text" pattern="[a-zA-Z0-9-_.,\s]*" size="10" maxLength="8" value={pinfo.title_text||''}  onInput={this.formChange} onBlur={this.handleFieldChange} />
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">First&nbsp;Name:&nbsp;</div>
              </td>
              <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="firstName" pattern="[a-zA-Z0-9-_\s]*" id="firstName" size="30" maxLength="20" value={pinfo.firstName||''}  onChange={this.formChange}/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">Last&nbsp;Name:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="lastName" id="lastName" size="30" pattern="[a-zA-Z0-9-_\s]*"  maxLength="21" value={pinfo.lastName||''}  onChange={this.formChange}/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td height="20" colSpan="3">
                <div align="right"></div>
              </td>
            </tr>
            <tr valign="top">
              <td >
                <div align="right">* Street&nbsp;Address:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td nowrap="true" >
                <div align="left">
                  <input type="text" name="addrStreet1" id="addrStreet1" pattern="[a-zA-Z0-9-_,.#\s]*" value={pinfo.addrStreet1||''} size="30" maxLength="50" onChange={this.formChange}/>
          	<img style={{display:this.state.errorAddress}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td >
                <div align="right">Apartment&nbsp;Number:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="addrApt" id="addrApt" pattern="[a-zA-Z0-9-_.,#\s]*" size="30" maxLength="14"  onChange={this.formChange}/>
                </div>
              </td>
            </tr>
            <tr valign="top">
              <td >
                <div align="right">* City:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td nowrap="true" >
                <div align="left">
                  <input type="text" name="addrCity" pattern="[a-zA-Z0-9-._\s]*" id="addrCity" size="30" maxLength="30" value={pinfo.addrCity||''}  onChange={this.formChange}/>

          	<img style={{display:this.state.errorCity}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>

                </div>
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">* State:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <select name="addrState" id="addrState" value={pinfo.addrState||'--'}  onChange={this.formChange}>
                    <option value="--">&nbsp;</option>
                    <option  value="AL">Alabama</option>
                    <option  value="AK">Alaska</option>
                    <option  value="AZ">Arizona</option>
                    <option  value="AR">Arkansas</option>
                    <option  value="CA">California</option>
                    <option  value="CO">Colorado</option>
                    <option  value="CT">Connecticut</option>
                    <option  value="DE">Delaware</option>
                    <option  value="DC">District of Columbia</option>
                    <option  value="FL">Florida</option>
                    <option  value="GA">Georgia</option>
                    <option  value="HI">Hawaii</option>
                    <option  value="ID">Idaho</option>
                    <option  value="IL">Illinois</option>
                    <option  value="IN">Indiana</option>
                    <option  value="IA">Iowa</option>
                    <option  value="KS">Kansas</option>
                    <option  value="KY">Kentucky</option>
                    <option  value="LA">Louisiana</option>
                    <option  value="ME">Maine</option>
                    <option  value="MD">Maryland</option>
                    <option  value="MA">Massachusetts</option>
                    <option  value="MI">Michigan</option>
                    <option  value="MN">Minnesota</option>
                    <option  value="MS">Mississippi</option>
                    <option  value="MO">Missouri</option>
                    <option  value="MT">Montana</option>
                    <option  value="NC">North Carolina</option>
                    <option  value="ND">North Dakota</option>
                    <option  value="NE">Nebraska</option>
                    <option  value="NV">Nevada</option>
                    <option  value="NH">New Hampshire</option>
                    <option  value="NJ">New Jersey</option>
                    <option  value="NM">New Mexico</option>
                    <option  value="NY">New York</option>
                    <option  value="OH">Ohio</option>
                    <option  value="OK">Oklahoma</option>
                    <option  value="OR">Oregon</option>
                    <option  value="PA">Pennsylvania</option>
                    <option  value="RI">Rhode Island</option>
                    <option  value="SC">South Carolina</option>
                    <option  value="SD">South Dakota</option>
                    <option  value="TN">Tennessee</option>
                    <option  value="TX">Texas</option>
                    <option  value="UT">Utah</option>
                    <option  value="VT">Vermont</option>
                    <option  value="VA">Virginia</option>
                    <option  value="WA">Washington</option>
                    <option  value="WI">Wisconsin</option>
                    <option  value="WV">West Virginia</option>
                    <option  value="WY">Wyoming</option>
                  </select>

          	<img style={{display:this.state.errorState}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>

                </div>
              </td>
            </tr>
            <tr valign="top">
              <td>
                <div align="right">* Zip&nbsp;Code:&nbsp;</div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td>
                <div align="left">
                  <input type="text" name="addrZip" id="addrZip" value={pinfo.addrZip||''}  onChange={this.formChange} size="5" pattern="[0-9]*" maxLength="5"/>
          	<img style={{display:this.state.errorZip}}  alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
          	<font style={{display:this.state.errorInvalidZip, color:'red'}}><b>Invalid Zip code.</b></font>

          	</div>
              </td>
            </tr>
            <tr valign="top">
              <td colSpan="3" >
                <div align="right">&nbsp;</div>
              </td>
            </tr>
            <tr valign="top">
              <td width="130" height="25">
                <div align="right">*&nbsp;Home&nbsp;Telephone&nbsp;Number: </div>
              </td>
  						  <td width="15">&nbsp;</td>
              <td >
                <div align="left">(<input type="text" name="phoneAreaCode" id="phoneAreaCode" size="3" maxLength="3" pattern="[0-9]*" value={pinfo.phoneAreaCode||''}   onChange={this.formChange} onKeyUp={(e)=>this.autoTab(e,'phonePrefix')}/>
                  {' '}){' '}
                  <input type="text" name="phonePrefix" id="phonePrefix" pattern="[0-9]*" size="3" maxLength="3"  value={pinfo.phonePrefix||''} onChange={this.formChange} onKeyUp={(e)=>this.autoTab(e,'phoneSufix')}/>
                  {' '}-<input type="text" name="phoneSufix" id="phoneSufix" pattern="[0-9]*" size="4" maxLength="4" value={pinfo.phoneSufix||''}  onChange={this.formChange}/>
          	<img style={{display:this.state.errorPhone}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
          <br/><div style={{display:this.state.errorInvalidPhone, color:'red'}}><b>Invalid phone number.</b></div>
          	<div style={{display:this.state.errorPhoneNmbrMismatch, color:'red'}}><b>The telephone number you provided does not match the one we have on record, please re-enter your telephone number.</b></div>
             </div>
              </td>
            </tr>
            <tr valign="top">
              <td >&nbsp;</td>
              <td>&nbsp;</td>
              <td >Please do not enter cell phones or business numbers.<a href="#" onClick={this.toggleHowSelected}> How
                  was my household selected?</a></td>
            </tr>

            <tr valign="top">
              <td >&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
            </tbody>
  	  </Table>
  	  </td>
  	  </tr>
  	  <tr>
  	  <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
  	  <td>&nbsp;</td>
  	  <td><b>* Do you, or does anyone in your household, <a href="#" onClick={this.toggleWork}>work</a> for a radio station or network, TV station or network, or cable system or network?</b><br/>
              <input type="radio" name="networkAffiliate" id="networkAffiliate"  checked ={pinfo.networkAffiliate === 'Y'}  onChange={this.formChange} value="Y"/>
              {' '}Yes{' '}
              <input type="radio" name="networkAffiliate" id="networkAffiliate"  value="N" checked ={pinfo.networkAffiliate === 'N'}  onChange={this.formChange} />
              {' '}No{' '}
          <img style={{display:this.state.errorAffiliation}}  alt="arrow" src={Arrow2} width="36" height="18" border="0"/>
               </td>
          </tr>
   	  <tr>
  	  <td colSpan="3">&nbsp;</td>
  	  </tr>
  	  <tr>
  	  <td valign="top"><b><font style={{color:"#000066"}}></font></b></td>
  	  <td>&nbsp;</td>
  	  <td><b>To make sure we send you the correct number of booklets, <em>including
  	      yourself</em>,
  	    how many people in your home are 12 years of age or older.</b></td>
  	  </tr>
  	  	  <tr>
  	  <td colSpan="3">&nbsp;</td>
  	  </tr>
  	  <tr>
  	  <td colSpan="2">&nbsp;</td>
  	  <td>
  	  <Table border="0" width="434">
      <tbody>
              <tr>
              <td width="261" valign="middle" nowrap="true"  >
                <div align="left">* Number of persons 12 years of age or older: </div>
              </td>
  						 <td nowrap="true" >
                             <select name="householdNum" value ={pinfo.householdNum || '0'}  onChange={this.formChange} id="householdNum">
                    <option value="0">&nbsp;</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">More than 9</option>
                             </select>

          	<img style={{display:this.state.errorHouseholdNmbr}} alt="arrow" src={Arrow2} width="36" height="18" border="0"/>

          	<font style={{display:this.state.pPHConfirm, color:'red'}}><b>Please correct.</b></font>
             </td>
              </tr>
              </tbody>
          </Table>		</td>
  	  </tr>
  	   <tr>
  	  <td colSpan="3">&nbsp;
  	  </td>
  	  </tr>
  	  <tr style={{display:this.state.error}}>
  	  	<td colSpan="2">&nbsp;</td>
  	  	<td><font style={{color:'red'}}><b>Fields cannot be blank.  Answers are required.</b></font>
  	  	</td>
  	  </tr>
      </tbody>
  	  </Table>
        <p align="left"></p>
        <p align="center"><button type="button" name="back" onClick={this.back}>Previous</button>{' '}<input type="submit" name="continue_to_3" value="Continue"/></p>
      </td>
    </tr>
  <tr>
    <td  width="433" colSpan="3">
      <EnLegalFooter/>
    </td>
  </tr>

  </tbody>
</Table>
</Form>
    </div>
    );
}
}


export default WithRouter(EnPersonalInfo);
