import React from 'react';

const EnCopyRights = () => {
    return (
      <p className="verysmall">Copyright &copy; {(new Date().getFullYear())} The Nielsen Company (US), LLC. All Rights Reserved.</p>



    );
};

export default EnCopyRights;
