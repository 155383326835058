import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import {Table} from 'react-bootstrap';
import SpLegalFooter from "../components/SpLegalFooter";
import SpHeader from '../components/SpHeader';


const SpAlreadyPart =(props)=> {

  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Nielsen Radio Ratings';
    window.addEventListener("popstate", () => {
     navigate(1);
   });
  });

  const toggleWhatsComing = () => {
    window.open('/SpWhatsComing','','scrollbars=no,resizable=no,height=450,width=450');
  }


  return (
    <div>
    <Table cellSpacing="0" cellPadding="0" border="0">

        <SpHeader screen="" image="bePart"/>

        <tr>
          <td width="19"  valign="top"></td>
          <td width="450"  colSpan="2" valign="top">
            <p>&nbsp;</p>
                <p>Gracias por su inter&eacute;s. De acuerdo con nuestros registros, su
                      hogar ya est&aacute; inscrito en la encuesta. <a href="#" onClick={toggleWhatsComing}>Espere la llegada de su
                      paquete de diarios por correo.</a> Si tiene alguna pregunta, comun&iacute;quese
                      con nosotros llamando al 1-800-204-3174.</p>
                      <p>&nbsp;</p>
                      </td>
                  </tr>

                      <SpLegalFooter/>
              </Table>
            </div>
    );
  };



export default SpAlreadyPart;
