import React from 'react';
import { Table} from 'react-bootstrap';

const SpLegalFooter = () => {
    return (
      <tr>
      <td valign="top"></td>
      <td colSpan="2">
      <Table>
    <tbody>
    <tr>
    <td width="444">
    <p align="left">
    </p>
    <p style={{fontSize:'x-small',marginBottom:'0px'}} align="center">
      <a href="http://www.nielsenradiodiary.com/privacy_es.htm" rel="noreferrer noopener" target="_blank">Declaraci&#243;n de Privacidad</a>
    {' '}  |{' '} <a href="http://www.nielsenradiodiary.com/terms_es.htm" rel="noreferrer noopener" target="_blank">T&eacute;rminos de uso</a>
      </p>
    </td>
  </tr>
  <tr>
    <td width="444" height="206" valign="top">
      <p style={{marginTop:"0px"}}className="verysmall" align="center">Copyright &copy; {(new Date().getFullYear())} The Nielsen Company (US), LLC. Todos los derechos reservados.</p>
    </td>
  </tr>
  </tbody>
  </Table>
  </td>
</tr>




    );
};

export default SpLegalFooter;
